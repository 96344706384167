.labelWrapper__2GnPe {
  margin-bottom: 15px;
  text-align: left;
}

.label__1KO2X {
  position: relative;
  font-weight: 600;
  color: #343434;
}

@media (max-width: 768px) {

.label__1KO2X {
    font-size: 0.75rem
}
  }

.input__1iZVF {
  width: 100%;
  margin-top: 7px;
  padding: 10px 15px;
  border-radius: 15px;
  height: 45px;
  margin-bottom: 0;
  border: 1px solid #9a9a9a;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxOCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTkuOTA5MTQgMTAuODY1NkM5LjQwNzAzIDExLjM3ODEgOC41OTI5NyAxMS4zNzgxIDguMDkwODYgMTAuODY1NkwwLjM3NjU3NyAyLjk5MDU4Qy0wLjEyNTUyNiAyLjQ3ODAxIC0wLjEyNTUyNiAxLjY0Njk5IDAuMzc2NTc3IDEuMTM0NDJDMC44Nzg2NzkgMC42MjE4NiAxLjY5Mjc1IDAuNjIxODYgMi4xOTQ4NSAxLjEzNDQyTDkgOC4wODEzNEwxNS44MDUxIDEuMTM0NDJDMTYuMzA3MyAwLjYyMTg2IDE3LjEyMTMgMC42MjE4NiAxNy42MjM0IDEuMTM0NDJDMTguMTI1NSAxLjY0Njk5IDE4LjEyNTUgMi40NzgwMSAxNy42MjM0IDIuOTkwNThMOS45MDkxNCAxMC44NjU2WiIgZmlsbD0iIzk5OTk5OSIvPjwvc3ZnPg==');
  background-repeat: no-repeat, repeat;
  background-position: right 1.5em top 50%, 0 0;
  background-size: 1em auto, 100%;
}

@media (max-width: 768px) {

.input__1iZVF {
    height: 40px
}
  }

.input__1iZVF:focus {
  border: 1px solid #9a9a9a;
}

.error__3imKY {
  border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.error__3imKY::after {
    border-radius: 1px solid rgba(255, 0, 0, 0.6);
  }

.errorText__9Pis1 {
  font-size: 0.6rem;
  color: red;
}

select::-ms-expand {
  display: none;
}

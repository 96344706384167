.timeGrid__mfeAp {
  display: grid;
  width: 100%;
  gap: 8px;

  grid-template-columns: minmax(130px, 1fr) minmax(130px, 1fr);

  color: #999999;

  overflow-x: overlay;
}

.day__3sxLs {
  font-weight: bold;
  margin-right: 6px;
}

.period__1yaGV {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 6px;

  font-size: 13px;
}

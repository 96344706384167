.marginBlock12__1vU8f {
  margin-block: 12px;
}

.commentsHeader__GM-Ua {
  justify-content: space-between;
  display: flex;
}

.orderOptions__1Z1ZT {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.orderOption__ePqTr {
  border: solid 1px #0146C7;
  color: #0146C7;
  border-radius: 10px;
  margin-top: 4px;
}

.orderIcon__1Nf3H {
  margin-left: 4px;
}

.orderByButton__3un0c {
  display: flex;
  align-items: center;
}

.nOfComments__YxE_x {
  margin: 0px;
}

.container__3zjus {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  width: 95%;
}

  @media (max-width: 768px) {.container__3zjus {
    height: auto
}
  }

.inviteTitle__2kOyE {
  color: #000000;
  margin: 0 0 15px 0;
  font-weight: 700;
  font-size: 1.3625rem;
}

@media (max-width: 768px) {

.inviteTitle__2kOyE {
    margin: 20px 0
}
  }

.inviteContent__2gxNP {
  color: #343434;
  text-align: center;
  font-size: 0.95rem;
  width: 100%;
}

.inviteContent__2gxNP p {
    margin-bottom: 30px;
  }

@media (max-width: 576px) {

.inviteContent__2gxNP {
    font-size: 0.8rem
}
  }

.form__1O1Tu {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 768px) {

.form__1O1Tu {
    width: 90%
}
  }

.buttons___o5O1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

@media (max-width: 768px) {

.buttons___o5O1 {
    width: 90%
}
  }

.invitesInput__1IeLj {
  border: 1px solid #d1d1d1;
  text-align: center;
}

.invitesButton__G-pNI {
  margin-top: 2vh;
  margin-bottom: 2vh;
  height: 33px;
}

.footer__286AH {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.8rem;
  font-weight: 500;
}

.footer__286AH svg {
    width: 12px;
  }

.footer__286AH span {
    text-decoration: underline;
  }

.successBtn__3KFP6 {
  margin-top: 2vh;
  margin-bottom: 1vh;
  width: 100%;
}

.notNow__2Ykj0 {
  font-weight: normal;
}

.social__1m5Yh {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20vh;
  border-top: 1px solid #D8DADE;
}

.social__1m5Yh h2 {
    font-size: 1rem;
  }

@media (max-width: 768px) {

.social__1m5Yh {
    width: 90%
}
  }

.emailBtn__2iacq {
  width: 80%;
  text-align: left;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.border__1LcNH {
  margin-bottom: 20px;
}

.radio__47R5P {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */

.radio__47R5P + img {
  cursor: pointer;
  -moz-filter: grayscale(1);
       filter: grayscale(1);
}

/* CHECKED STYLES */

.radio__47R5P:checked + img,
.radio__47R5P + img:hover{
    -moz-filter: grayscale(0);
         filter: grayscale(0);
}

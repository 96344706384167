.wrapper__2cn9Q{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageWrapper__3or9k{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}

.optionsWrapper__2XF5x{
    display: flex;
    flex-direction: column;
}

.title__2VBEB{
    width: 25rem;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    margin: 30px 0px;
    align-self: center;
}

.subTitle__2RexG{
    color: #E38ACA;
    text-align: center;
}

@media (max-width: 768px) {

.subTitle__2RexG{
        text-align: left;
        margin-left: 15px
}
    }

.contentWrapper__1mDN5{
    padding: 5px 15px;
    width: 24rem;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.contentWrapper__1mDN5 .section__d2or4{
        margin-top: 15px;
        width: 100%;
    }

.contentWrapper__1mDN5 .section__d2or4 a{
            color: #125EED;
        }

.contentWrapper__1mDN5 .section__d2or4 p{
            margin-top:5px;
            text-decoration: underline;
        }

.contentWrapper__1mDN5 .section__d2or4 .noLink__2-vpc{
            font-size: 0.75rem;
            color:black;
            text-decoration: none;
        }

.buttonWrapper__25ykr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }

.buttonWrapper__25ykr button {
        margin: 0px 10px;
    }

.graffitiFooter__2nVDw {
    position: fixed;
    width: 30rem;
    bottom: -9vh;
    left: -3vw;
  }

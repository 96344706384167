.headingSpace__1-drJ {
  margin-top: 12px;
  margin-bottom: 24px;
}

.summary__2nF0C {
  display: grid;
  grid-template-columns: minmax(160px, 1fr) minmax(160px, 1fr) minmax(160px, 1fr);
  grid-column-gap: 6px;
  overflow-x: overlay;

  min-height: 150px;
}

.indicator__3Hstb {
  margin-block: 8px;
  display: flex;
  align-items: center;
}

.iconWithNumber__2456- {
  display: flex;
  align-items: center;
}

.iconWithNumber__2456- b {
    margin-left: 8px;
    color: #707070;
  }

.checkIcon__SHgrk {
  color: #9DCD5B;
}

.clockIcon__Keqvr {
  color: #EE724F;
  margin-left: 12px;
}

.collaboratorsGrid__3ast- {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-column-gap: 8px;
}

@media(max-width: 1500px) {

.collaboratorsGrid__3ast- {
    grid-template-columns: repeat(2, minmax(100px, 1fr))
}
  }

@media (max-width: 1060px) {

.collaboratorsGrid__3ast- {
    grid-template-columns: repeat(1, minmax(100px, 1fr))
}
  }

.themeColorBorderButton__17BBb {
  border-color: #0146C7;
  color: #0146C7;

  margin-block: 12px;
}

.marginBlock12__3MUK7 {
  margin-block: 12px;
}

.startDrawer__3Z2WK {
  width: 100%;
  height: 325px;
  background-color: white;
  padding-top: 165px;
  padding-bottom: 15px;
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

.close__5eMRV {
    margin-left: auto;
  }

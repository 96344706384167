.placeCardButtonWrapper__31Bb6 {
  width: 100%;
  background-color: transparent;
  margin-bottom: 30px;
  border-bottom: 1px solid #fcf1f1;
  min-height: 120px;
}

.placeCard__11Mqm {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
}

@media (max-width: 768px) {

.placeCard__11Mqm {
    width: 100%
}
  }

.markerWrapper__1ICZz {
  position: relative;
}

.markerWrapper__1ICZz img {
    position: absolute;
    height: 60px;
    width: auto;
    transform: translate(30%, -80%);
  }

.infoWrapper__ehY3U {
  padding-top: 20px;
}

.placeContentWrapper__3Fu-O {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 3vh;
  align-items: center;
}

@media (max-width: 576px) {

.placeContentWrapper__3Fu-O {
    width: 99%
}
  }

.placeWrapper__2HrTa {
  width: 65%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
  padding-left: 4%;
}

.placeWrapper__2HrTa > * {
    margin-top: 2px;
  }

.placeWrapper__2HrTa h1 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    font-weight: 700;
    color: #000000;
  }

.placeWrapper__2HrTa h2 {
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 0.675rem;
    font-weight: 400;
    color: #343434;
    margin-bottom: 5px;
  }

.withoutReviewButton__KwLh4 {
  justify-content: flex-start;
}

.fullSpacePlaceWrapper__2oZQi {
  width: 75%;
}

.twoColumns__1-Wha {
  display: flex;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-end;
}

.twoColumns__1-Wha > h2 {
    word-wrap: wrap;
  }

.twoColumns__1-Wha > a {
    color: #125EED;
    font-size: 0.675rem;
    font-weight: 700;
  }

.buttons__1YyX9 {
  display: flex;
  flex-direction: column;

  height: 100%;
  margin: 0 auto;
}

.singleButton__2ygKa {
  margin: 0;
}

.btn__XSmS9 {
  text-decoration: underline;
  font-size: 0.65rem;
  color: #0146C7;
  font-weight: bold;
}

.modal__3jeoC {
  text-align: center;
}

.modal__3jeoC h1 {
    margin-bottom: 20px;
  }

.modalContent__19ULQ {
  text-align: left;
  color: #4c4c4c;
  padding: 10px;
}

.modalContent__19ULQ ul {
    list-style-type: disc;
  }

.modalContent__19ULQ li {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 0.75rem;
  }

.ratingText__LkP-S {
  color: #125EED;
}

.detailsText__1DFVF {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
}

.detailsText__1DFVF span {
    margin-inline: 4px;
  }

.detailsText__1DFVF span:first-child {
    margin-left: 0;
  }

.flexContainer__2-qmg {
  display: flex;
  min-width: 100px;
}

.wrappableText__1eLET {
  white-space: break-spaces !important;
}

.fullWidth__2o1WX {
  width: 100%;
}

.inlineContainer__mMKcA {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

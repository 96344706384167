.detailTitle__32rzu {
  color: #ffffff;
  text-align: center;
}

.wrapper__3afcg {
  width: 80%;
}

.wrapper__3afcg > h2 {
    margin: 20px 0;
    text-align: center;
    color: #ffffff;
  }

.detailsContentTitle__qqOiu {
  font-size: 1.25rem;
  margin: 40px 0;
  color: #ffffff;
}

.detailsContentSubtitle__3cLAT {
  font-size: 1rem;
  margin: 0 auto;
  text-align: center;
  width: 60%;
  color: #ffffff;
}

.aboutUsContainer__2gazq {
  position: relative;
  margin: 40px 0;
  width: 85%;
}

.aboutUsContainer__2gazq > p {
    color: #ffffff;
    text-align: center;
    padding: 15px;
    font-size: 0.875rem;
  }

.column__2CQJM {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.themeColorBorderButton__WXLkM {
  border-color: #0146C7;
  color: #0146C7;
  background-color: #fff;

  margin-block: 12px;
}

.ticket__ZB9cL {
  max-width: 75px;
}

.couponDrink__1jexd {
  max-width: 250px;
}

.mainWrapper__wJJXa {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 90vh;
  overflow-y: auto;
}

  @media (max-width: 768px) {.mainWrapper__wJJXa {
    font-size: 0.7rem
}
  }


.mainLogo__2hA_H {
  display: flex;
  width: 124px;
  min-height: 124px;
}


.mainLogo__2hA_H svg {
    margin: auto;
    width: 5rem;
  }


.mainSponsors__v8hom {
  margin: 0 auto;
}


.signLanguageLink__2oSoz {
  display: block;
}

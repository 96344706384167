.arrow__2UBQV {
    border: solid #343434;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.right__2Ygmf {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.left__2XY_B {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.up__t9G6g {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down__17ilX {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.signUpWrapper__CiFHJ {
  text-align: center;
  height: 90vh;
}

.container__-NU0B {
  text-align: center;
  clear: both;
}

.signUpModal__3040T {
  width: 90%;
  max-width: 640px;
}

@media(max-width: 576px){

.signUpModal__3040T {
    height: 90vh
}
  }

.modalForm__7lNag {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  max-height: 700px;
  min-height: unset;
  height: 98vh;
}

@media (max-width: 576px) {

.modalForm__7lNag {
    height: 80vh
}
  }

.dataFormBackground__IcN94 {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 576px) {

.dataFormBackground__IcN94 {
    width: 90%
}
  }

.checkboxSection__2mK8S {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.passFormBackground__W8_qr {
  background-image: url(/img/pass_background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 80%;
  margin: 20px auto auto auto;
  height: 65%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 576px) {

.passFormBackground__W8_qr {
    background-image: url(/img/pass_background-responsive.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: white;
    align-items: unset;
    min-height: 360px
}
  }

.dataFormContent__1B1Bx {
  margin-top: 20px;
  width: 100%;
  text-align: left;
}

@media (max-height: 576px) {

.dataFormContent__1B1Bx {
    margin-top: 61px
}
  }

.passFormContent__zm5d8 {
  margin-bottom: 20px;
  width: 50%;
}

@media (max-width: 576px) {

.passFormContent__zm5d8 {
    margin-top: 110px
}
  }

.checkboxWrapper__3-vMg {
  position: relative;
  display: inline-flex;
  margin-top: 10px;
}

@media (max-width: 576px) {
    .checkboxWrapper__3-vMg > p {
      font-size: 0.75rem;
    }
  }

.checkboxError__y03si {
  color: red;
}

.close__5ocWV {
  opacity: 0.8;
  transition: 500ms ease;
}

.modalTitle__2klSa {
  color: #000;
  text-align: left;
  padding: 0 20px;
}

.modalTitle__2klSa.center__2XlXs {
    text-align: center;
  }

@media (max-width: 768px) {

.modalTitle__2klSa {
    padding: 0;
    font-size: 1rem
}
  }

.signUpBtn__3hStW {
  color: #000;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms ease;
}

.signUpBtn__3hStW:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }

.nav__38ks3 {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto auto auto auto;
}

@media (max-width: 768px) {

.nav__38ks3 {
    width: 80%
}
  }

.nav__38ks3 button {
    margin-bottom: 10px;
  }

.hero__1KU6c {
  height: 180px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {

.hero__1KU6c {
    width: 40%;
    height: auto
}
  }

.radio__3aSGe {
  margin: 10px;
}

@media (max-width: 768px) {

.radio__3aSGe {
    margin: 5px
}
  }

.form__2-jVU {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 85%;
  margin: 5px auto auto auto;
}

@media (max-width: 768px) {

.form__2-jVU {
    width: 100%
}
  }

.pagination__lO0qr {
  display: flex;
  margin: 10px auto;
  padding-bottom: 20px;
  justify-content: space-between;
  grid-column: span 3;
}

@media (max-width: 768px) {

.pagination__lO0qr {
    margin: 20px
}
  }

.dataForm__3til4 {
  width: 100%;
  overflow-y: overlay;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

@media (max-height: 1200px) and (max-width: 768px) {

.dataForm__3til4 {
    height: 70%
}
  }

@media (max-height: 768px) {

.dataForm__3til4 {
    height: 75vh
}
  }

.dataModal__3q-BI,
.passModal__CRql2 {
  max-width: 800px;
  padding: 0 !important;
  width: 95%;
}

.dataFormLink__2Zf6u {
  color: #0146C7;
  font-size: 0.75rem;
}

.terms__2aM1M {
  display: inline;
  text-align: left;
  font-size: 0.8rem;
}

@media(max-width: 768px) {

.terms__2aM1M {
    font-size: 1rem
}
  }

.navBtn__3U03S {
  color: #0146C7;
  border: 1px solid #0146C7;
  width: 124px;
  margin-right: 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.navBtn__3U03S:last-of-type {
    margin-right: 0;
  }

.navBtn__3U03S:hover {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }

.btnNext__1wjXC {
  color: #fff;
  font-weight: bolder;
  background-color: #0146C7;
}

.errorText__vhACu {
  margin: 20px auto 0 auto;
  margin-top: 20px;
  font-size: 0.75rem;
  color: red;
  text-align: center;
}

.rowWrapper__1Vtci {
  display: flex;
  max-width: 100%;
  margin: auto;
}

.rowWrapper__1Vtci > label {
    margin: auto 0 10px 0;
    width: 100%;
  }

.rowWrapper__1Vtci > label + label {
    margin-left: 10px;
  }

.checkboxErrorMessage__2T30l {
  position: absolute;
  top: -1.3rem;

  color: white;
  font-weight: bolder;
  background-color: #d04945;
  border: none;
  font-size: 0.5rem;
  text-align: center;
  border-radius: 15px;
  padding: 4px 8px;
}

.biggerField__2d7g0 {
  flex: 2;
}

.smallerField__1BpTh {
  flex: 1;
}

.mobileContainer__G463Y {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 21;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pcdlabel__1T66S {
  white-space: nowrap;
  position: relative;
  font-weight: 600;
  color: #343434;
  font-size: 0.75rem;
  margin-bottom: 10px;
}

.pcd__a7mwu {
  display: flex;
}

.disability__l8W-c {
  width: 200px;
}

@media (max-width: 768px) {

.disability__l8W-c {
    width: 150px
}
  }

.passwordStrength__ZL2Dh {
  margin: 0;
  flex-direction: row;
  align-items: center;
}

.passwordStrength__ZL2Dh svg path {
    fill: #e5e5e5;
  }

.passwordStrength__ZL2Dh svg.checked__fIZOO path {
    fill: #1f734c;
  }

.passwordStrength__ZL2Dh p {
    color: #605f5f;
    font-size: 12px;
    margin-left: 8px;
  }

.labelStack__15zix {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block: 12px;
}

.emailValidation__1lzdr {
  padding: 20px;
  height: 90vh;
  min-height: 450px;
  display: flex;
  flex-direction: column;
}

.emailValidation__1lzdr form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

.emailValidation__1lzdr .bold__1e9-v {
    font-weight: 500;
  }

.emailValidation__1lzdr > * {
    margin-bottom: 15px;
  }

.wrapper__3sHeT{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageWrapper__2Y7EA{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.imageWrapper__2Y7EA img{
        max-width: 300px;
      }

.notPlaceOwner__2Vvxq {
  margin-bottom: 40px;
  margin-top: 40px;
}

.optionsWrapper__3zLHH{
    display: flex;
    flex-direction: column;
    flex:2;
}

.title__1A8Hw{
    max-width: 31rem;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    margin: 30px 0px;
    align-self: center;
}

.stampWrapper__2OBW-{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 12em;
}

.stampBox__2CN4i{
    background-color: #0146C7;
    padding: 5px;
    max-width: 20rem;

    border: solid 2px transparent;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.stampBox__2CN4i .contentInfo__1uNP-{
        background-color: white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex:1;
        width: 100%;
        padding: 10px 25px;
    }

.stampBox__2CN4i .iconWrapper__2Mymp{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 10px 0px 0px;
    }

.stampBox__2CN4i .infoWrapper__fVsBB svg{
            width: 2em;
        }

.stampBox__2CN4i .infoWrapper__fVsBB span{
            display: none;
        }

.stampBox__2CN4i .placeName__24lJ5{
        font-weight: 600;
        font-size: 1rem;
        margin: 15px 0px 10px 0px;
    }

.stampBox__2CN4i .reviewsText__3xLlj{
        font-size: 0.75rem;
        margin: 15px 0px 0px 0px;
    }

.stampBox__2CN4i .contentTitle__3Z-b0{
        margin-bottom: 20px;
        text-align: center;
        font-size: 1rem;
        padding: 0px 10px;
    }

.stampBox__2CN4i .stampName__U9qrh{
        margin: 20px 0px 10px 0px;
        color: white;
        display: flex;
        align-items:center;
        justify-content:center;
        font-weight: bold;
    }

.stampBox__2CN4i .stampName__U9qrh svg{
            margin-right: 5px;
            height: 25px;
            width: 25px;
        }

.stampMessage__v4DiE{
    text-align: center;
    margin-top: 15px;
    max-width: 20rem;
}

.stampSectionMore__31f39{
    display: flex;
    flex-direction: row;
    align-self: center;
    gap: 10px;
    font-size: 0.8rem;
    font-weight: 500;
}

.stampSectionMore__31f39 svg {
        width: 12px;
    }

.stampSectionMore__31f39 span {
        text-decoration: underline;
    }

.buttonWrapper__2Dlf8 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }

.buttonWrapper__2Dlf8 a {
        margin-top: 15px;
        color: #125EED;
        text-decoration: underline;
    }

.graffitiFooter__3fsFG {
    position: fixed;
    width: 30rem;
    bottom: -9vh;
    left: -3vw;
  }

/*COLLABORATOR VIEW*/

.title__1A8Hw{
    width: 25rem;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    margin: 30px 0px;
    align-self: center;
}

.subTitle__28D03{
    align-self: center;
    text-align: center;
    width: 25rem;
    padding: 0px 30px 10px 30px
}

.contentWrapper__31b98{
    padding: 5px;
    width: 24rem;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.contentWrapper__31b98 .cardWrapper__2sl6-{
        display: flex;
        align-items: center;
        justify-content:center;
        border-radius: 10px;
        box-shadow: 1px 2px 3px 3px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        padding: 20px;
    }

.contentWrapper__31b98 .iconWrapper__2Mymp{
        flex:0.2;
    }

.contentWrapper__31b98 .infoWrapper__fVsBB{
        flex:1;
    }

.contentWrapper__31b98 .infoWrapper__fVsBB .answers__1lCX6{
            color: #1F734C;
            font-weight: bold;
        }

.contentWrapper__31b98 .infoWrapper__fVsBB .answers__1lCX6 span{
                font-weight: 100;
            }

.contentWrapper__31b98 .infoWrapper__fVsBB .rightAnswers__3kEGx{
            font-weight: bold;
            margin: 5px 0px 10px 0px;
        }

.contentWrapper__31b98 .infoWrapper__fVsBB .message__eACjC{
            color: gray;
        }

.corretAnswersLink__3FjbW {
  display: block;
}

.column__3uoXV {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.themeColorBorderButton__2h-_o {
  border-color: #0146C7;
  color: #0146C7;
  background-color: #fff;

  margin-block: 12px;
}

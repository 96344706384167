.dataField__ygJJN {
  max-width: 100%;
  margin: auto;
  margin-bottom: 10px;
  display: block;
}

  .dataField__ygJJN > p {
    text-align: left;
    padding-left: 10px;
    color: black;
    font-weight: 600;
  }

  .dataField__ygJJN > input,
  .dataField__ygJJN > div,
  .dataField__ygJJN > select,
  .dataField__ygJJN > textarea {
    max-width: 100%;
    min-height: 45px;
    border-radius: 20px;
    font-size: 1em;
    resize: none;
  }

  @media (max-width: 576px) {

  .dataField__ygJJN > input,
  .dataField__ygJJN > div,
  .dataField__ygJJN > select,
  .dataField__ygJJN > textarea {
      min-height: 32px;
      font-size: 0.8em
  }
    }

  .dataField__ygJJN > input,
  .dataField__ygJJN > select,
  .dataField__ygJJN > textarea {
    border: 1px solid #d5d5d5;
  }

  .dataField__ygJJN > .error__3pIcO {
    border: 1px solid rgba(255, 0, 0, 0.6);
  }

.errorText__A7Qfj {
  font-size: 0.6rem;
  color: red;
}

.textAreaField__7DoTj {
  width: 100%;
  margin-top: 7px;
  padding: 10px 15px;
  outline: none;
}

.label__1rNV- {
  white-space: nowrap;
  position: relative;
  color: #000000;
}

@media (max-width: 768px) {

.label__1rNV- {
    font-size: 1.2em
}
  }

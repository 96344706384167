.avatarContainer__37xqV {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 20px;
}

.avatarSelected__2HZk9 {
  position: absolute;
  border: solid 3px #0146C7;
  height: 120px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  width: 120px;
  inset: 0 0 2px 0;
  margin: auto;
}

.footerActions__3mt8y {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.currentContainer__1M56V {
  display: flex;
  flex: 1;
  justify-content: center;
}

.iconAvatarContainer__JgXbL {
  background-color: #0146C7;
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}

.iconAvatarContainer__JgXbL svg {
    color: white;
    width: 25px;
  }

.submittedWrapper__3tv2W {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  flex-grow: 1;
}

  @media (max-width: 768px) {

  .submittedWrapper__3tv2W h1 {
      font-size: 20px;
      margin-bottom: 8px
  }
    }

  .submittedWrapper__3tv2W > p {
    /* color: white; */
    font-size: 1rem;
    width: 300px;
    text-align: center;
  }

  @media (max-width: 768px) {

  .submittedWrapper__3tv2W > p {
      font-size: 0.8rem;
      margin-bottom: 8px
  }
    }

.button__2Vwcw {
  height: 38px;
  max-width: 237px;
  width: 80%;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #125EED;
}

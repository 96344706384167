.wrapper__1GGbg {
  color: white;
  background-color: #0146C7;
  border-radius: 26px;
  font-size: 0.75rem;
  cursor: pointer;
  outline: none;
  width: 270px;

  display: flex;
  align-items: center;
  flex-direction: row;
}

  @media (max-width: 576px){.wrapper__1GGbg {
    width: 245px
}
  }

.themeDefault__2jKNd {
  background-color: #0146C7;
}

.themeWhite__2KXZc {
  border: 1px solid #D2D2D2;
  background: #fff;
  color: #0146C7;
  width: auto;
  padding: 0 40px;
  z-index: 2;
}

@media (max-width: 576px){

.themeWhite__2KXZc {
    width: 100%;
    padding: 40px 20px
}
  }

.button__2Jyhy {
  text-transform: none;
  padding: 15px;

  display: flex;
  align-items: center;
  flex-direction: row;
}

.close__2OObp {
  border: none;
  width: 12px;
  margin-top: 3px;
}

@media (max-width: 576px){

.close__2OObp {
    display: none
}
  }

.close__2OObp line {
  stroke: white;
}

@media (max-width: 576px){

.themeWhite__2KXZc .close__2OObp {
    display: block;
    width: 27px;
    top: -15px;
    right: 10px
}
  }

.themeWhite__2KXZc .close__2OObp line {
  stroke: #D2D2D2;


}

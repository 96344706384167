.column__24UZG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marginBlock12__2F2lR {
  margin-block: 12px;
}

.status__mKo-Y {
  font-size: 12px;
  font-weight: 600;

  display: flex;
  align-items: center;
}

.status__mKo-Y svg {
    margin-right: 8px;
  }

.finished__3lPo0 {
  color: #8D68DC;
}

.doing__2z0Oa {
  color: #F04742;
}

.flexEnd__1cgSH {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1;
}

.checkboxSection__kJDsC {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 15%;
  width: 100%;
}

@media (max-width: 576px) {

.checkboxSection__kJDsC {
    width: 90%
}
  }

.dataFormLink__2G3hp {
  color: #cc298e;
  font-size: 0.75rem;
}

.checkboxErrorMessage__3Qvl8 {
  position: absolute;
  top: -1.3rem;

  color: white;
  font-weight: bolder;
  background-color: #d04945;
  border: none;
  font-size: 0.5rem;
  text-align: center;
  border-radius: 15px;
  padding: 4px 8px;
}

.checkboxWrapper__26uKA {
  position: relative;
  display: inline-flex;
  margin-top: 1.5rem;
}

@media (max-width: 576px) {
    .checkboxWrapper__26uKA > p {
      font-size: 0.6em;
    }
  }

.terms__3IUV- {
  display: inline;
  text-align: left;
  font-size: 0.7rem;
}

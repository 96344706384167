.spaceBetween__3oJuM {
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 12px;
}

.sortBy__2LJ6Y {
  color: #707070;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.sortBy__2LJ6Y svg {
    margin-left: 8px;
  }

.marginTop12__20Omy {
  margin-top: 12px;
}

.marginBottom24__pt9-Z {
  margin-bottom: 16px;
}

.inline__1r4YL {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inline__1r4YL svg {
    margin-left: 8px;
  }

.summary__2Esho {
  display: grid;
  grid-template-columns: minmax(110px, 1fr) minmax(110px, 1fr) minmax(110px, 1fr);
  overflow-x: overlay;
  min-height: 170px;
  max-height: 210px;
}

.indicator__XOrT2.spaceBetween__3oJuM {
    display: inline-flex;
  }

.goodBad__1vTG5 {
  padding-top: 12px;
  display: flex;
  max-width: 130px
}

.progressWithGoal__gnv9x {
  max-width: 150px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progressWithGoal__gnv9x >div {
      flex: 1;
    }

.progressWithGoal__gnv9x >svg{
      margin-left: -5px;
      width: 25px;
    }

.happyIcon__3kexR {
  color: #9DCD5B;
  margin-right: 12px;
}

.sadIcon__2rXsS {
  color: #EE604E;
  margin-inline: 12px;
}

.columnDirection___hQz5 {
  flex-direction: column;
  align-items: flex-start;
}

.complementaryMaterialsCard__1So76 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.complementaryMaterialsCard__1So76 img {
    max-width: 75px;
  }

.complementaryMaterialsCard__1So76 section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 12px;
  }

.complementaryMaterialsCard__1So76 section p {
      margin-block: 6px;
    }

.rating__xmj8k {
  padding-top: 11px;
}

.rating__xmj8k
svg {
  width: 1.3vw;
  max-width:15px;
}

@media (max-width: 768px) {

.rating__xmj8k
svg {
  width: 3vw;
  max-width: 12px;
  min-width: 10px
}
}

.rating__xmj8k
h1 {
  font-size: clamp(12px, 1.3vw, 16px);
  color: #707070;
}

@media (max-width: 768px) {

.rating__xmj8k
h1 {
  font-size: 0.8rem
}
}

.goodBadCount__3t4Wt {
  color: #707070;
  font-weight: 700;
  font-size: clamp(12px, 1.3vw, 16px);
}

@media (max-width: 768px) {

.goodBadCount__3t4Wt {
    font-size: 0.8rem
}
  }

.aboutUsContainer__3nJOZ {
  position: relative;
  margin: 40px 0;
  width: 85%;
}

  .aboutUsContainer__3nJOZ > p {
    text-align: center;
    padding: 15px;
    font-size: 0.875rem;
  }

.btnBlock__vtnqt {
  margin: auto;
  display: block;
  border: 0;
  width: 60%;
  padding: 8px 24px;
  font-size: 0.85rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #E38ACA;
}

.detailTitle__3SYyI {
  text-align: center;
}

@media (max-width: 1000px) {
  .btnBlock__vtnqt {
    max-width: 75%;
  }
}

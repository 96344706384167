.boxInput__3QHEz {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 95%;
}

  @media (max-width: 768px) {.boxInput__3QHEz {
    width: 100%;
    overflow-y: overlay;
    margin-top: 0
}
  }

.navButtons__13-7q {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.navButtons__13-7q > :first-child {
    margin: 5px 0;
    margin-bottom: 3vh;
  }

.navButtons__13-7q > a,
  .navButtons__13-7q > button {
    width: 100%;
  }

.modal__10doh {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 35vh;
  width: 25vw;
}

.modal__10doh div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 70%;
  }

.modal__10doh div p {
      font-size: 0.8rem;
      color: #343434;
    }

.modal__10doh div a {
      width: 50%;
    }

@media (max-width: 1200px) {

.modal__10doh {
    width: 50vw
}
  }

@media (max-width: 576px) {

.modal__10doh {
    width: 100vw
}

    .modal__10doh div {
      width: 90%;
    }
  }

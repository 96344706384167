.checkbox__j2YId {
  height: 20px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

  @media (max-width: 768px) {.checkbox__j2YId {
    height: 15px;
    min-width: 15px
}
  }

.checkbox__j2YId::after {
  content: "";
  display: inline-block;
  background: white;
  border: 2px solid #999999;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;
}

@media (max-width: 768px) {

.checkbox__j2YId::after {
    height: 15px;
    width: 15px;
    border-radius: 2px
}
  }

.checkbox__j2YId::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 7px;
  height: 10.7px;
  border-right: 2.5px solid white;
  border-bottom: 2.5px solid white;
  top: 8.5px;
  left: 70%;
  transform: translate(-30%, -45%) rotate(40deg);
  font-size: 0.875rem;
  z-index: 2;
  opacity: 0;
}

@media (max-width: 768px) {

.checkbox__j2YId::before {
    border-width: 1.5px;
    width: 5px;
    height: 8px;
    left: 50%;
    top: 6.5px
}
  }

.checkbox__j2YId:checked::before {
  display: block;
  opacity: 1;
}

.checkbox__j2YId:checked::after {
  background: #0146C7;
  border: 2px solid #999999;
}

.disabled__ZlvXJ::after {
  border: 1px solid #878B97;
  cursor: not-allowed;
}

.disabled__ZlvXJ:checked::after {
  border: 1px solid #878B97;
}

.disabled__ZlvXJ::before {
  border-right: 2.5px solid #343434;
  border-bottom: 2.5px solid #343434;
  cursor: not-allowed;
}

.container__lFdjk {
  overflow-y: overlay;
  margin-inline: 2.5em;
  margin-top: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

  @media (max-width: 576px) {.container__lFdjk {
    margin-inline: 0
}
  }

.termsSection__3OihS {
  /* overflow-y: overlay; */
  max-height: 80vh;
}

.showInLibrasSection__1fWGs {
  text-align: center;
  margin-top: 24px;
  text-decoration: underline;
}

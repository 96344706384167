.stepperWrapper__3eE6a {
  font-family: Arial;
  display: flex;
  justify-content: center;
}

.stepperItem__norx0 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

@media (max-width: 768px) {

.stepperItem__norx0 {
    font-size: 12px
}
  }

.stepperItem__norx0::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #ccc;
  width: 100%;
  left: -50%;
  z-index: 2;
}

.stepperItem__norx0::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #ccc;
  width: 100%;
  left: 50%;
  z-index: 2;
}

.stepperItem__norx0 .stepCounter__3MZT3 {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepperItem__norx0.active__2saq_ {
  font-weight: bold;
}

.stepperItem__norx0.completed__2dVsM .stepCounter__3MZT3 {
  background-color: #1F00B1;
}

.stepperItem__norx0.completed__2dVsM::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #1F00B1;
  width: 100%;
  left: 90%;
  z-index: 3;
}

.stepperItem__norx0::before,
.stepperItem__norx0::after,
.stepperItem__norx0.completed__2dVsM::after {
  top: 4px;
}

.stepperItem__norx0:first-child::before {
  content: none;
}

.stepperItem__norx0:last-child::after {
  content: none;
}

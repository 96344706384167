*,
*::after,
*::before {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #999999;
}

::-webkit-scrollbar:horizontal{
  height: 6px;
  background-color: white;
}

*::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #E6E6E6;
}

*::-webkit-scrollbar-track:horizontal{
  box-shadow: none;
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #999999;
}

*::-webkit-scrollbar-thumb:horizontal{
  border-radius: 8px;
  background-color: white;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #999999;
}

*:hover::-webkit-scrollbar-thumb:horizontal{
  background-color: white;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

p {
  margin: 0;
  font-size: 0.75rem;
}

html {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-size: 1rem;
}

body {
  margin: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
}

button,
input {
  margin: 0;
  padding: 0;
  border: 0;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

button:hover {
  cursor: pointer;
}

a {
  color: unset;
  text-decoration: none;
}

select {
  -webkit-appearance: none;
}

label {
  font-size: 0.75rem;
  color: #ffffff;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 1.25rem;
}

h2 {
  font-size: 0.75rem;
}

h3 {
  font-size: 0.625rem;
}

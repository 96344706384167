.container__iAGzS {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: overlay;
  height: 90vh;
  width: 95%;
}

.inviteTitle__KyT2i {
  color: #000000;
  margin: 0 0 15px 0;
  font-weight: 700;
  font-size: 1.3625rem;
}

@media (max-width: 768px) {

.inviteTitle__KyT2i {
    margin: 20px 0
}
  }

.inviteContent__3mb0t {
  color: #343434;
  text-align: center;
  font-size: 0.95rem;
}

.inviteContent__3mb0t p {
    margin-bottom: 10px;
  }

@media (max-width: 576px) {

.inviteContent__3mb0t {
    font-size: 0.8rem
}
  }

.form__3uuFw {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
}

.bottomLink__2dsSS {
  font-weight: normal;
}

.invitesInput__3MPOo {
  border: 1px solid #d1d1d1;
  text-align: center;
}

.invitesButton__4Ikkd {
  margin-top: 2vh;
  margin-bottom: 2vh;
  height: 33px;
  width: 90%;
}

.footer__QeP2d {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.8rem;
  font-weight: 500;
}

.footer__QeP2d svg {
    width: 12px;
  }

.footer__QeP2d span {
    text-decoration: underline;
  }

.successBtn__26MwY {
  margin-bottom: 1vh;
}

.container__11kif {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  width: 95%;
}

.inviteTitle__1Abln {
  color: #000000;
  font-weight: 600;
  font-size: 1rem;
}

.inviteContent__uc-BG {
  color: #343434;
  text-align: center;
  font-size: 0.95rem;
}

.inviteContent__uc-BG p {
    margin-bottom: 30px;
  }

@media (max-width: 576px) {

.inviteContent__uc-BG {
    font-size: 0.75rem
}
  }

.form__2yvhN {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 768px) {

.form__2yvhN {
    width: 90%
}
  }

.invitesInput__2IwzJ {
  border: 1px solid #d1d1d1;
  text-align: center;
}

.invitesButton__PojF8 {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.footer__gHe-z {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.75rem;
  font-weight: 500;
}

.footer__gHe-z svg {
    width: 12px;
  }

.successBtn__3jJyp {
  margin-bottom: 1vh;
}

.social__38vQ_ {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 20vh;
  border-top: 1px solid #D8DADE;
}

.social__38vQ_ h2 {
    font-size: 1rem;
  }

.hint__1ofbC {
  color: #1F734C;
  font-size: 0.75rem;
  padding: 5px;
}

.modalContent__2ztvD {
  text-align: center;
  padding: 20px;
}

.modalContent__2ztvD img {
    width: 150px;
  }

.modalContent__2ztvD .button__2OMOL {
    width: 100%;
  }

.modalContent__2ztvD > * {
    margin-bottom: 10px;
  }

@media (max-width: 768px) {

.modalContent__2ztvD {
    width: 80%
}
  }

.wrapper__2Gsp3{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.questionWrapper__eR2ns{
    flex: 1;
    background-color: #8C307D;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.questionWrapper__eR2ns p {
        color: white;
        font-size: 2rem;
    }

.optionsWrapper__vSeyG{
    flex:1;
}

.optionsSentence__2vCN_{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0px;
}

.optionsSentence__2vCN_ svg{
        margin-right: 5px;
        fill: #FA8F00;
    }

.optionsSentence__2vCN_ p{
        font-size: 1.5rem;
        font-weight: 700;
        
    }

@media (max-width: 768px){

.optionsSentence__2vCN_{
        justify-content: start;
        margin: 25px 5px
}
        .optionsSentence__2vCN_ p {
            font-size: 1rem;
        }
    }

.buttonWrapper__1Rsry {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

.buttonWrapper__1Rsry button {
        margin: 0px 10px;
    }

.graffitiFooter__1R9iN {
    position: fixed;
    width: 30rem;
    bottom: -6vh;
    z-index: -1;
  }

.radioWrapper__ivKfQ{
    display: flex;
    justify-content: center;
  }

@media (max-width: 768px){

.radioWrapper__ivKfQ{
        flex-direction: column;
        align-items: center
  }
    }

.radio__2urBy{
    background-color: #D4E3FF;
    width: 23rem;
    height: 5rem;
    border-radius: 5px;
    margin: 0px 10px;

  }

@media (max-width: 768px){

.radio__2urBy{
        margin: 5px 5px

  }
    }

.radio__2urBy span {
        font-weight: normal;
        border-radius: 10px;

    }

.radio__2urBy :nth-child(1){
        z-index: 1;
        width: 100%;
    }

.radio__2urBy input:checked ~ .customCheckmark__O-tBH {
        background-color: transparent;

        border: double 2px transparent;
        border-radius: 10px;
        background-image: linear-gradient(white, white), repeating-linear-gradient( 45deg, red, orange, yellow, green, blue, indigo, violet, red);
        background-origin: border-box;
        background-clip: content-box, border-box;
    }

.activeRadio__1N9rA{
    background-color: transparent;
  }

.customCheckmark__O-tBH{
    width: 20rem;
    height: 5rem;
    box-shadow: none;
    border:none;
  }

@media (max-width: 768px){

.customCheckmark__O-tBH{
        width: 23rem
  }
    }

.radioContentWrapper__39AxX{
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

.radioContentWrapper__39AxX .textPurple__3qQfh {
        color: #0146C7
    }

.radioContentWrapper__39AxX span {
        flex:0.1;
        background-color: #FFFFFF;
        color: #125EED;
        font-weight: 600;
        padding: 10px 5px;
        border-radius: 40px;
        margin-right: 10px;
        border: solid 1px #D4E3FF;
    }

.radioContentWrapper__39AxX p{
        flex:1;
        text-align: left;
    }

.radioContentWrapper__39AxX svg{
        flex:0.2;
        fill: #2DA66E;
        font-size: 2.5rem;
        margin-right: 10px;
    }

.errorText__G-Ob_ {
    margin: 20px 0px;
    font-size: 1rem;
    color: red;
    text-align: center;
  }

.floatingMenu__3sxap {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  height: 300px;
  width: 120px;
  margin-left: auto;
  pointer-events: none;
}

.menuContent__3EENR {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.menu__qE_3Y {
  pointer-events: auto;
  top: 50%;
  position: absolute;
}

.menu__qE_3Y .menuTitle__2rV2l {
    font-size: 0.625rem;
    margin-top: 5px;
    color: #ffffff;
    display: none;
    animation: titleMount__1jCl7 0.3s linear;
  }

.menu__qE_3Y:hover {
    transform: scale(calc(5 / 3));
  }

.menu__qE_3Y:hover .menuContent__3EENR {
      transform: translate(-50%, -50%) scale(calc(3 / 5));
    }

.menu__qE_3Y:hover .menuTitle__2rV2l {
      display: block;
    }

.menuIcon__1NHLz {
  height: 25px;
}

.locationsMenu__3ja-r {
  top: 0;
  right: 60px;
  cursor: not-allowed;
  background-color: #8368d8;
}

.peopleMenu__1BfWG {
  top: 80px;
  right: 120px;
  cursor: not-allowed;
  background-color: #e38aca;
}

.complaintsMenu__EHKEg {
  top: 170px;
  right: 120px;
  cursor: not-allowed;
  background-color: #d04945;
}

.partnersMenu__3EZrb {
  top: 240px;
  right: 60px;
  cursor: not-allowed;
  background-color: #edc20e;
}

@keyframes titleMount__1jCl7 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .floatingMenu__3sxap {
    top: unset;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    margin: 0 auto;
    height: 120px;
    width: 300px;
  }

  .locationsMenu__3ja-r {
    top: unset;
    bottom: 60px;
    right: 0;
  }

  .peopleMenu__1BfWG {
    top: unset;
    bottom: 110px;
    right: 70px;
  }

  .complaintsMenu__EHKEg {
    top: unset;
    bottom: 110px;
    right: 170px;
  }

  .partnersMenu__3EZrb {
    top: unset;
    bottom: 60px;
    right: 240px;
  }
}

.hero__32zo- {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 180px;
  min-height: 150px;
  background-position: center;
  background-size: cover;
  background-color: gray;
}

.actionMenu__bYEbn {
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
  padding-left: 4%;
  width: 100%;
  /* overflow: hidden; */
  background-color: #ffffff;
}

  .actionMenu__bYEbn > li + li {
    padding-left: 2rem;
  }

  .actionMenu__bYEbn a {
    float: left;
    display: inline-block;
    color: #D8DADE;
    text-align: center;
    text-decoration: none;
    font-size: 0.75rem;
  }

  .actionMenu__bYEbn a:hover {
    color: #000000;
  }

  .actionMenu__bYEbn a.active__3Dwza {
    color: #000000;
  }

  .actionMenu__bYEbn a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    background: #000;
    transition: width 0.3s;
  }

  .actionMenu__bYEbn a:hover:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    transition: width 0.3s;
  }

  .actionMenu__bYEbn a.active__3Dwza:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    transition: width 0.3s;
  }

.disabled__2wyBL {
  cursor: not-allowed;
}

.disabled__2wyBL > a {
    cursor: not-allowed;
  }

.disabled__2wyBL > a:hover {
    color: #D8DADE;
  }

.disabled__2wyBL > a.active__3Dwza {
    color: #D8DADE;
  }

.disabled__2wyBL > a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    background: #D8DADE;
  }

.disabled__2wyBL > a:hover:after,
  .disabled__2wyBL > a.active__3Dwza:after {
    all: initial;
  }

.modal__1FcFd {
  padding: 0 !important;
}

.bePart__3SoXU {
  width: 70%;
}

.loginWrapper__1oFLu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 5vw 20px 5vw;
  width: 100%;
  padding-top: 20px;
}

@media (max-width: 768px) {

.loginWrapper__1oFLu {
    padding: 0
}
  }

.loginHeader__3qVMp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.carouselWrapperClass__2xf_K {
  align-items: center;
}

.carouselWrapperClass__2xf_K ul li:last-child {
    align-items: flex-start;
  }

.boxInput__1JCML {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 95%;
}

  @media (max-width: 768px) {.boxInput__1JCML {
    width: 100%;
    overflow-y: overlay;
    margin-top: 0
}
  }

.navButtons__Am8eD {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.navButtons__Am8eD > :first-child {
    margin: 5px 0;
    margin-bottom: 3vh;
  }

.navButtons__Am8eD > a,
  .navButtons__Am8eD > button {
    width: 100%;
  }

.modal__3oSpv {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 35vh;
  width: 25vw;
}

.modal__3oSpv div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 70%;
  }

.modal__3oSpv div p {
      font-size: 0.8rem;
      color: #343434;
    }

.modal__3oSpv div a {
      width: 50%;
    }

.success__348VM {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-weight: 500;
  font-size: 1rem;
  line-height: 29px;
  color: #02D78A;
  margin-bottom: 1vh;
}

@media (max-width: 576px){

.success__348VM {
    font-size: 0.7rem
}
  }

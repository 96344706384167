.btn__XfeAM {
    position: fixed;
    bottom: 40px;
    left: calc(50% - 290px);
}
    @media (max-width: 576px){
.btn__XfeAM {
        bottom: -20px;
        left: 0px
}
    }
  
.hide__3KphZ {
    display: none;  
}
  
.cookieIcon___xlZp {
    width: 25px;
    height: 20px;
    margin-right: 5px;
    text-align: center;
}
  
@media (max-width: 576px){
  
.cookieIcon___xlZp {
        width: 40px;
        transform: scale(1.5, 1.5);
        margin-right: 20px
}
    }
  
.bold__2FvZO {
    margin-right: 5px;
    font-weight: bold
}
  
.text__2fROQ {
    display: flex;
}
  
@media (max-width: 576px){
  
.text__2fROQ {
        font-size: 14px;
        display: initial
}
    }
  
.marker__3aa-T {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  .marker__3aa-T > img {
    width: 65px;
    height: 106px;
    margin-bottom: 20px;
  }

.pacContainer__WmNw8 {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 100%;
  height: 40px;
}

  .pacContainer__WmNw8 svg {
    min-width: 15px;
    margin-right: 5px;
  }

  .pacContainer__WmNw8 img {
    width: 25px;
    margin-right: 5px;
  }

  .pacContainer__WmNw8 p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pacContainer__WmNw8 span {
    font-weight: 600;
  }

.noMarker__2pTl5 > p {
  margin-left: 10px;
}

.secondaryText__dgsnc {
  color: #343434;
  font-size: 0.65rem;
}

.mainText__2P777 {
  font-weight: 500;
}

.mainText__2P777 span {
    color: #343434;
    font-size: 0.65rem;
    font-weight: normal;
  }

.wrapper__gEb9n {
    position: fixed;
    background-color: white;
    width: 101%;
    height: 100%;
    z-index: 20;
    overflow-y: overlay;
    top:0;
}

.contentWrapper__lGOni{
    display: flex;
    width: 100%;
    height: 100%;
}

@media (max-width: 992px){

.contentWrapper__lGOni{
        flex-direction: column
}
    }

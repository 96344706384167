.rowContainer__1DRNX {
  display: grid;
  grid-template-columns: 16px 1fr;
  color:#4D4D4D;
  font-weight: 400;

}

  .rowContainer__1DRNX svg {
    color: #999999;
  }

  .rowContainer__1DRNX h1,
  .rowContainer__1DRNX h2,
  .rowContainer__1DRNX h3,
  .rowContainer__1DRNX h4 {
    color: #343434;
    margin-block: 4px;
  }

.iconContainer__1BuXl {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-top: 5px;
}

.description__JjvJ- {
  overflow-x: overlay;
  width: 100%;
  margin-left: 12px;
}

.ratingForm__2YDl4 {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 23px 23px 23px 23px;
  font-size: 16px;
  width: 90%;
  overflow-y: overlay;
  height: 86vh;
  position: absolute;
  top: 120px;
  margin: 0 20px auto;
}

  @media(max-width: 768px){.ratingForm__2YDl4 {
    border-radius: 0;
    margin: 0;
    top: 50px;
    width: 100%;
    height: 100%
}
  }

.header__X1N-9 {
  background: #125EED;
  border-radius: 23px 23px 0px 0px;
  font-size: 16px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
}

@media(max-width: 768px){

.header__X1N-9 {
    border-radius: 0;
    width: 100%;
    padding: 30px
}
  }

.ratingForm__2YDl4 h2 {
  width: 90%;
  text-align: center;
  line-height: 2;
  margin: 0 auto;
}

@media (max-width: 768px) {

.ratingForm__2YDl4 h2 {
    width: 95%
}
  }

.ratingForm__2YDl4 span {
  font-weight: bold;
}

.ratings__1eU3B {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  transition: transform 620ms ease;
}

.ratings__1eU3B.active__24REu {
    transform: translateX(-100%);
  }

.starsWrapper__1nahV {
  min-width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.starsWrapper__1nahV > h2 {
    text-align: center;
  }

.markerWrapper__3qGCN img {
    margin-top: 10px;
    height: auto;
    width: 40px;
  }

@media (max-width: 768px) {

.markerWrapper__3qGCN span {
      font-size: 0.75rem
  }
    }

.stars__1RAKJ {
  outline: none;
}

.tagsWrapper__1A6vt {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.childTag__2zvBf {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #125EED;
  text-align: center;
  font-size: 0.75rem;
  color: #000000;
  outline: none;
  padding: 5px 10px;
  margin-top: 10px;
  margin-left: 5px;
}

@media (max-width: 768px) {

.childTag__2zvBf {
    font-size: 0.7rem;
    padding: 6px
}
  }

.childTags__3Nrvg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
  row-gap: 10px;
}

.childTags__3Nrvg > button + button {
    margin-left: 10px;
  }

.selectedTag__15Iwl {
  background: #0146C7 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-color: transparent;
}

.form__3sgaC {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-top: 10px;
  width: auto;
}

.form__3sgaC textarea {
  width: 80%;
  height: 90px;
  background-color: #D4E3FF;
  border: none;
  border-radius: 20px;
  padding: 20px;
  outline: none;
  resize: none;
}

@media (max-width: 768px) {

.form__3sgaC textarea {
    font-size: 0.7rem
}
  }

.form__3sgaC textarea:focus:-ms-input-placeholder {
  content: ";";
}

.form__3sgaC textarea:focus::placeholder {
  content: ";";
}

.form__3sgaC h3 {
  margin-bottom: 10px;
  margin-top: 0px;
}

.form__3sgaC .btnSubmit__1b3_c {
  margin: 15px 0px;
  color: #ffffff;
  background-color: #125EED;
  width: 80%;
  text-transform: uppercase;
}

.underThreeError__FUXhg {
  text-align: center;
  width: 70%;
  margin: auto;
  font-size: 0.7em;
  color: red;
}

.btnSubmitWithError__m0oTs {
  margin-bottom: 5px !important;
}

.buttons__30euL {
  max-width: 400px;
  display: flex;
  justify-content: space-around;
  margin: auto;
}

.ratingButton__3_d5Y {
  margin: auto;
  margin-top: 20px;
  padding: 8px 45px;
  height: 35px;
  font-size: 0.85rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #125EED;
}

.aboutLink__2kzNY {
  font-size: 0.75rem;
  margin: 100px 0px 15px 0px;
  text-align: center;
}

.aboutLink__2kzNY > a {
    text-decoration: underline;
  }

.knowMore__2_FX_ {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.knowMore__2_FX_ > button {
    color: #000000;
  }

.reviewModal__1cFR5 {
  color: black;
  padding: 20px;
  max-height: 80vh;
}

.instructionsWrapper__vGPH5 {
  overflow: overlay;
  text-align: justify;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
}

.instructionsWrapper__vGPH5 > p {
    font-size: 0.75rem;
    margin: 10px;
  }

.instructionsWrapper__vGPH5 > button {
    margin: auto;
    background: #125EED;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bolder;
  }

@media (max-width: 1000px) {
  .ratingButton__3_d5Y {
    margin-top: 40px;
  }

  .ratings__1eU3B {
    transition: unset;
  }
}

.grateful__1u5M- {
  margin-bottom: 20px;
}

.grateful__1u5M- p {
    font-size: 1rem;
  }

.helpMore__34p_x {
  margin-top: 5%;
  width: 100%;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 20px;
  outline: none;
  color: #fff;
  background-color: #0146C7;
}

.modalContent__1wkZl {
  text-align: center;
  padding: 20px;
}

.modalContent__1wkZl img {
    width: 150px;
  }

.modalContent__1wkZl .btn__3ceaR {
    width: 100%;
  }

.modalContent__1wkZl > * {
    margin-bottom: 10px;
  }

.container__2o5yh {
  position: relative;
  display: flex;
  flex: 1;
  margin-top: 32px;
}

.header__2_6jk {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.header__2_6jk h1 {
    margin-left: 5px;
  }

@media (max-width: 450px) {

.header__2_6jk {
    font-size: 1rem;
    align-items: flex-end
}
  }

.titleText__26St8{
  font-size: 1.5rem;
}

@media (max-width: 450px) {

.titleText__26St8{
      font-size: 1rem
}
  }
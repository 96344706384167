.circleBackground__L1Tre,
.circleProgress__2iMFb {
  fill: none;
}

.circleBackground__L1Tre {
  stroke: #ffffff;
}

.circleInside__kOdKc {
  stroke: #125EED;
  fill: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circleProgress__2iMFb {
  stroke: #3AD68E;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circleText__EUBrK {
  font-size: 1rem;
  font-weight: bold;
  fill: #000000;
}

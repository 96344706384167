.banner__YXcUQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
  
  .banner__YXcUQ .hero__3Mthw {
    margin-right: 20px;
    width: 120px;
  }
  
  @media (max-width: 768px){.banner__YXcUQ {
    margin-left: 35px
}
  }

.bannerText__3ZBn-{
  font-size: 0.8rem;
  color: #343434;
  line-height: 20px;
}

@media (max-width: 576px){

.bannerText__3ZBn-{
    font-size: 0.75rem
}
  }

.bannerText__3ZBn- button {
    background-color: #0146C7;
    color: #ffffff;
    padding: 8px 13px;
    border-radius: 25px;
    margin-top: 10px;
  }

.banner__3t9Dp {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
  margin-left: 35px;
}

  .banner__3t9Dp .hero__1kX8m {
    height: 60px;
  }

.bannerText__mukp3 {
  font-size: 0.8rem;
  color: #343434;
  line-height: 20px;
}

@media (max-width: 576px) {

.bannerText__mukp3 {
    font-size: 0.75rem
}
  }

.bannerText__mukp3 button {
    background-color: #0146C7;
    color: #ffffff;
    padding: 8px 13px;
    border-radius: 25px;
    margin-top: 10px;
  }

.marginBlock12__BN58u {
  margin-block: 8px;
}

.menuItem__4dlfF {
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 20px 20px;
  margin-left: 20px;
  max-width: 320px;
}
  .menuItem__4dlfF > * {
    margin-right: 10px;
  }

.label__2PqCt {
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.label__2PqCt h1 {
    font-size: 0.75rem;
  }

.label__2PqCt p {
    font-weight: normal;
    font-size: 0.65rem;
  }

.image__icDZQ {
  max-width: 60px;
  min-width: 30px;
}

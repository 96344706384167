.accordion__xqtIo {
  list-style: none;
}

.accordion_item__197qg {
  border-top: 1px solid #9f9f9f;
}

.accordionButton__3sA7K {
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  border: none;
}

.moduleNumberWrapper__1JfjB {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  margin-right: 10px;
  position: relative;
}

.moduleNumberWrapper__1JfjB img {
    border-radius: 50%;
  }

.moduleNumberWrapper__1JfjB span {
    position: absolute;
    background-color: white;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    color: #8D68DC;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.moduleInfoWrapper__2Kkl- {
  flex: 1;
}

.moduleInfoWrapper__2Kkl- h1 {
    font-size: 1rem;
  }

.moduleInfoWrapper__2Kkl- p {
    font-size: 0.89rem;
    font-weight: lighter;
  }

.modal__3SLoH {
  padding: 0 !important;
  height: 80vh;
}

.loginWrapper__3S1Vy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 5vw 20px 5vw;
}

.loginHeader__3xL0j {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.accordion__3OgAB {
    list-style: none;
}

.accordion_item__1clKm {
    border-top: 1px solid #9f9f9f;
}

.accordionButton__2MejY {
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border: none;
}

.moduleNumberWrapper__378_y{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:0.1;
    margin-right: 10px;
    position: relative;
}

.moduleNumberWrapper__378_y img {
        border-radius: 50%;
    }

.moduleNumberWrapper__378_y span {
        position: absolute;
        background-color: white;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        color: #8D68DC;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.moduleInfoWrapper__1M9QF{
    flex:1;
    
}

.moduleInfoWrapper__1M9QF h1{
        font-size: 1rem;
    }

.moduleInfoWrapper__1M9QF p {
        font-size: 0.89rem;
        font-weight: lighter;
    }

.duration__qlT4U{
    color: #CC298E;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: lighter;
    margin: 5px 0px;
}

.duration__qlT4U svg {
        fill: #CC298E;
        margin-right: 5px;
    }

.control__3dNLf {
    font-size: 20px;
    align-self: flex-start;
}

.control__3dNLf svg {
        fill: black;
    }

/* activate toggle */

.childWrapper__3SEtR {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

.cardBlocked__73ARu {
    position: relative;
  }

.cardBlocked__73ARu h1, .cardBlocked__73ARu p, .cardBlocked__73ARu svg {
        opacity: 0.5;
    }

.cardBlocked__73ARu:after {
    /* requerido */
    content: '';

    /* estilo de preto quase transparente */
    background-color: black;
    opacity: 0;

    /* posiciona nos limites do elemento pai */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* suavização básica */
    transition: 200ms all;
  }
.rating__1yJoA {
  text-align: center;
  font-size: 20px !important;
  color: #e38aca !important;
}

  .rating__1yJoA > span {
    font-size: 0.5rem;
  }

.ratingWrapper__2qc9H {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.fiveStarsRating__2ZsWf {
  display: flex;
  align-items: center;
}

.fiveStarsRating__2ZsWf span {
  font-size: 0.8rem;
  display: flex;
  align-items: flex-start;
  margin: 1px 5px 0 5px;
}

.fiveStarsRating__2ZsWf span.-noReviews__3mZnz {
    white-space: nowrap;
    font-size: 0.75rem;
    font-weight: 300;
  }

.ratingWrapper__2qc9H.-establishmentCard{
  align-items: flex-start;
}

.ratingWrapper__2qc9H.-establishmentCard .fiveStarsRating__2ZsWf {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

.ratingWrapper__2qc9H.-establishmentCard .fiveStarsRating__2ZsWf span {
      margin-left: 0;
      font-size: 12px;
    }

@media (max-width: 576px) {

.ratingWrapper__2qc9H.-establishmentCard .fiveStarsRating__2ZsWf {
        gap: 0
  }
    }

.filter__1VKPg {
  white-space: nowrap;
  font-weight: 600;
}

.default__2HmgY {
  border: 1px solid #ffffff;
  border-radius: 8px;
}

.letterDisplay__2ZzOS {
  width: 18px;
  height: 18px;
  padding-top: 1.5px;
  font-size: 0.75rem;
  color: #ffffff;
  text-align: center;
  border-radius: 100%;
}

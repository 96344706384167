.rating__3hTbM
    svg {
      width: 14px;
    }
  .rating__3hTbM
    h1 {
      font-size: 1rem;
    }
.starRating__2X0I8 {
  display: flex;
  flex-direction: row;
}
.numberOfStars__2u5Y9 {
  font-size: 12px;
  padding-top: 5px;
  color: #4d4d4d;
  white-space: nowrap;
  width: 25%;
}
.numberOfReviews__1Ih-u {
  font-size: 12px;
  padding-top: 5px;
  padding-left: 15px;
  color: #4d4d4d;
}
.bar__2Gjaf {
  width: 90%;
  height: 10px;
}
.infoAbout__2P4td {
  align-self: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 11px;
  color: #4d4d4d;
}
.nameOfPlace__3fHpb {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.container__2_WMQ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.placeCardButtonWrapper__1AzHe {
  width: 90%;
  background-color: transparent;
  margin-bottom: 10px;
  min-height: 90px;
}

.placeCard__1koyL {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding-bottom: 10px;
  z-index: 2;
  border-bottom: 1px solid #D8DADE;
}

.markerWrapper__hue_J {
  position: relative;
  margin-right: 2vh;
}

.markerWrapper__hue_J img {
    max-height: 60px;
  }

.placeWrapper__2ucLu {
  width: 85%;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.ellipsis__3zu5x {
  width: 100%;
  color: #343434;
  font-size: 0.8rem;
  font-weight: 600;
}

.columnWrapper__167On {
  color: #343434;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}

.columnWrapper__167On > * {
    padding-right: 4px;
  }

.columnWrapper__167On h1 {
    font-weight: 500;
    font-size: 0.75rem;
  }

@media (max-width: 576px) {
    .columnWrapper__167On > * {
      padding-right: 2px;
    }
  }

.address__EYvFd {
  color: #343434;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 400;
  font-size: 0.75rem;
}

.address__EYvFd:last-of-type {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.cardRating__2U84N {
  margin-bottom: 0px;
  font-size: 0.75rem;
}

.more__2jZNt {
  height: 100%;
  border: 0;
}

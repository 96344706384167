.coupon__z-bDt {
  padding: 10px;
  border: 2px solid #02D78A;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 90px auto 80px;
  align-items: center;
  min-height: 80px;
  height: auto;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 10px;
}

  @media(max-width: 576px) {.coupon__z-bDt {
    grid-template-columns: 70px auto 70px
}
  }

.sponsor__eWowK img {
    width: 70px;
  }

.description__1DBFC {
  text-align: left;
  padding: 0px 10px 0px 10px;
}

.description__1DBFC p {
    font-weight: bold;
    font-size: 0.75rem;
    margin-bottom: 3px;
  }

.description__1DBFC h3 {
    color: #B3B6BF;
  }

.exchangeValue__2jF4P {
  border-left: 2px solid #EFF0F2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exchangeValue__2jF4P strong {
    font-size: 1.25rem;
  }

.exchangeValue__2jF4P p {
    font-size: 0.75rem;
  }

.inactive__3ovAd {
  border: 2px solid #B3B6BF;
}

.inactive__3ovAd img {
    filter: grayscale(100%);
  }

.inactive__3ovAd .exchangeValue__2jF4P {
    color: #B3B6BF;
  }

.link__1sl1V {
  display: flex;
  flex-direction: row;
  color: #0146C7;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 10px;
}

.link__1sl1V svg {
    width: 10px;
    margin-left: 5px;
    fill: #0146C7;
    transform: scaleX(-1);
  }

.active__135yx {
  color: #02D78A;
}

.active__135yx svg {
    fill: #02D78A;
  }

.over__Nr5kO {
  border: 2px solid #D04945;
}

.over__Nr5kO .link__1sl1V,
.over__Nr5kO .exchangeValue__2jF4P {
  color: #D04945;
}

.over__Nr5kO .link__1sl1V svg {
    display: none;
  }

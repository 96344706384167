.mobileWrapper__2upeF {
  position: fixed;
  z-index: 20;
  background: #fff;
  height: 100%;
  width: 100%;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.illustration__3_AZr {
  height: auto;
}

.illustration__3_AZr img {
    max-width: 380px;
    max-height: 340px;
  }

.illustration__3_AZr::after {
  content: "";
}

.itemWrapper__1Elnv :nth-child(1) {
    height: auto;
  }

.title__1sUfe {
  font-size: 1rem;
  font-weight: 700;
}

.subTitle__gTJVZ {
  color: #f84b00;
  font-size: 1rem;
  margin: 15px 0px;
  font-weight: 600;
}

.contentWrapper__V0XP8 {
  align-self: center;
  max-width: 30rem;
}

.moduleContentWrapper__2FPNS {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.moduleContentWrapper__2FPNS p {
    font-weight: normal;
    text-align: left;
  }

.moduleNumberWrapper__1kZl6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.1;
  margin-right: 10px;
  position: relative;
}

.moduleNumberWrapper__1kZl6 img {
    border-radius: 50%;
  }

.moduleNumberWrapper__1kZl6 span {
    position: absolute;
    background-color: white;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    color: #8D68DC;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.duration__34lKD {
  color: #f84b00;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 5px 0px;
  justify-content: center;
}

.duration__34lKD svg {
    fill: #f84b00;
    margin-right: 5px;
  }

.wrapper__dzV6a {
  width: 100%;
  height: 80vh;

  display: flex;
  flex-direction: column;
  overflow-y: overlay;
}

.imageWrapper__2xs_u {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 768px) {
    .imageWrapper__2xs_u img {
      width: 100%;
    }
  }

.description__3jCd3 {
  padding: 0 5%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
}

.subTitleWrapper__2JtdM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  margin: 25px 0px 15px 0px;
  align-self: center;
  max-width: 30rem;
}

.subTitleWrapper__2JtdM p {
    line-height: 1.5em;
  }

.boldText__1mQjT {
  font-weight: bold;
}

.regularFont___nkEE {
  font-size: 1rem;
}

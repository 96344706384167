.searchboxWrapper__30gy4 {
  height: 45px;
  width: 100%;
  max-width: 550px;
  position: relative;
}

  @media (max-width: 576px) {.searchboxWrapper__30gy4 {
    height: 40px
}
  }

.searchboxInput__1Qt6G {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1px 20px 1px 25px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #D8DADE;
  border-radius: 33px;
  cursor: text;
  background-color: white;
}

@media (max-width: 768px) {

.searchboxInput__1Qt6G {
    border-radius: 30px
}
  }

.searchbox__imIrW {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
}

.active__2JV5q {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.itemsWrapper__1DqCj {
  position: absolute;
  border: 1px solid #d2d2d2;
  background-color: white;
  border-top: 0;
  border-bottom-left-radius: 23px;
  border-bottom-right-radius: 23px;
  width: 100%;
  max-height: 60vh;
  overflow-y: overlay;
  z-index: 1;
}

.itemsWrapper__1DqCj > li {
    padding: 10px 30px;
    cursor: pointer;
  }

@media (max-width: 576px) {

.itemsWrapper__1DqCj > li {
      padding: 2px 10px
  }
    }

.searchboxLoader__FnNAG {
  position: absolute;
  right: 30px;
  height: 50%;
}

.marker__GmSHL {
  padding-right: 5px;
}

.marker__GmSHL svg {
    width: 18px;
  }

.marker__GmSHL:hover {
  cursor: pointer;
  opacity: 0.7;
}

.row__BMNxB  {
  height: 60%;
  border-left: 1px solid #D8DADE;
  margin: 10px;
}

.searchIcon__32EGn {
  height: 25px;
}

.places__3aIHx > div {
  background-color: #8369d8;
}

.default__1-Dlr > div {
  background-color: #47ebeb;
}

.themeColor__1C0FL > div {
  background-color: #D4E3FF;
}

.loader__2jDIY {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 20px;
  max-height: 100%;
}

.loader__2jDIY div {
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    animation: loader__2jDIY 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

.loader__2jDIY div:nth-child(1) {
      left: 0;
      animation-delay: -240ms;
    }

.loader__2jDIY div:nth-child(2) {
      left: 12px;
      animation-delay: -120ms;
    }

.loader__2jDIY div:nth-child(3) {
      left: 24px;
    }

@keyframes loader__2jDIY {
  0% {
    height: 180%;
  }
  50%,
  100% {
    height: 100%;
  }
}

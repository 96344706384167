.container__A3fCW {
  overflow-y: overlay;
  margin-inline: 2em;
  display: flex;
  flex: 1;
  flex-direction: column;
}

  @media (max-width: 576px) {.container__A3fCW {
    margin-inline: 0
}
  }


.cardContainer__hISyF {
  margin-inline: 5em;
  margin-block: 2.5em;
}


@media (max-width: 768px) {


.cardContainer__hISyF {
    margin-inline: 2em
}
  }


@media (max-width: 576px) {


.cardContainer__hISyF {
    margin-inline: 0
}
  }


.textCenter__St5HJ {
  text-align: center;
}


.mt3__38c2g {
  margin-top: 24px;
}


.bottomLink__uxzsz {
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.wrapper__jcJyJ {
  width: 100%;
  height: 80vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.imageWrapper__1U1CP {
  height: 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;

  position: relative;
}

.imageWrapper__1U1CP::after {
  content: "Beba com moderação";
  color: #fff;
  display: block;
  font-size: 0.75rem;
  position: absolute;
  font-weight: 600;
  bottom: 16px;
  right: 24px;
}

@media (max-width: 768px) {

.imageWrapper__1U1CP::after {
    bottom: 8px;
    right: 8px
}
  }

.description__22cVr {
  min-height: 230px;
  padding: 3vw;

  display: flex;
  flex-direction: column;
  text-align: center;
}

.description__22cVr h1 {
    font-size: 1rem;
    margin-bottom: 2.5%;
  }

.description__22cVr p {
    font-size: 0.75rem;
    line-height: 1rem;
  }

.description__22cVr ul {
    font-size: 0.75rem;
    line-height: 1rem;
  }

.subTitleWrapper__1jqDL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

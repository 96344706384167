.marginBlock12__2HYET {
  margin-block: 12px;
}

.modal__BBNky {
  width: 60%;
}

@media (max-width: 768px) {

.modal__BBNky {
    width: 50%
}
  }

@media (max-width: 576px) {

.modal__BBNky {
    width: 90%
}
  }

.modal__BBNky h1 {
    margin-bottom: 30px;
  }

.filtersContent__1V7wC > h4 {
    margin: 0;
    padding-bottom: 20px;
  }

@media (max-width: 768px) {

.filtersContent__1V7wC > h4 {
      padding-bottom: 10px
  }
    }

.stampsHeader__1OrJ4 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-bottom: 20px;
}

.stampsHeader__1OrJ4 > h4 {
    margin: 0;
  }

.stampsHeader__1OrJ4 > button {
    background: inherit;
    color: #125EED;
    font-weight: bold;
  }

@media (max-width: 768px) {

.stampsHeader__1OrJ4 {
    padding-bottom: 10px
}
  }

.stamps__pJNxK {
  margin-bottom: 32px;
}

.filterList__1gZwk {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 1em;
  padding-top: 10px;
}

@media (max-width: 768px) {

.filterList__1gZwk {
    flex-wrap: nowrap;
    overflow-x: auto
}
  }

.filterOption__1RQaC {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background: #f7f7f9;
  margin-right: 8px;
  border-radius: 32px;
  padding: 8px 18px;
  outline: none;
}

.filterOption__1RQaC img {
    width: 10px;
    margin-right: 5px;
  }

@media (max-width: 768px) {

.filterOption__1RQaC {
    white-space: nowrap;
    font-size: 0.8rem
}
  }

.selectedFilterOption__3AZDJ {
  background-color: #f9e8f4;
}

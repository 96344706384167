.container__3B-Nl {
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 24px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.label__1qyBd {
  line-height: 1.85;
  color: #707070;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {

.label__1qyBd {
    margin-left: 0
}
  }

.disabled__3z4k3 {
  cursor: not-allowed;
}

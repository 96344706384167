.progressWrapper__6RPm-{
    flex: 1;
    overflow-y: overlay;
    padding-bottom: 5em;
}

.videoContainer__1laFZ{
    flex: 2;
    background-color: white;
    align-self: flex-start;
    position: relative;
}

@media (max-width: 992px){

.videoContainer__1laFZ{
        flex: 0;
        flex-direction: column
}
    }

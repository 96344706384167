.menuItem__Xspmp {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  row-gap: 3px;
  padding: 0px 27px;
  margin: 0;
  width: 100%;
}
  .menuItem__Xspmp svg {
    margin-right: 1.5rem;
  }

.menuItem__Xspmp:hover span {
    color: #B3B6BF;
  }

hr {
  border: none;
  height: 0.1rem;
  color: #F9F9F9; /* old IE */
  background-color: #F9F9F9; /* Modern Browsers */
  width: 90%;
}

.menuFont__2qTZj {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;

  color: #000000;
}

@media (max-width: 320px) {

.menuFont__2qTZj {
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
  }

.detailsText__1bb6E {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
}

  .detailsText__1bb6E span {
    margin-inline: 4px;
  }

  .detailsText__1bb6E span:first-child {
    margin-left: 0;
  }

.ratingText__3waW0 {
  color: #125EED;
}

.marginBlock12__XS5VF {
  margin-block: 12px;
}

.centeredContainer__2_FBw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

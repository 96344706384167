.signUpModal__1tN8E {
  width: 90%;
  max-width: 640px;
  height: 90vh;
}

.container__3w3zs {
  display: flex;
  flex-direction: column;
  padding-inline: 25%;
  text-align: center;
}

@media (max-width: 576px) {

.container__3w3zs {
    padding-inline: 20px
}
  }

.center__3dF7o {
    display: block;
    text-align: center;
    margin-block: 12px;
}

.marginBlock12__3wbtE {
  margin-block: 12px;
}

.childItemWrapper__2t7Tg{
    border-top: 1px solid #9f9f9f;
}

.childButton__3O3OZ {
    font-size: 16px;
    text-align: left;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.childButton__3O3OZ .videoTumbnail__1HLPq{
        flex:0.2;
        margin-right: 10px;
    }

.childButton__3O3OZ .videoTumbnail__1HLPq img {
            border-radius: 5px;
        }

.childButton__3O3OZ .duration__1Mqar{
        color: gray;
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: lighter;
    }

.childButton__3O3OZ .duration__1Mqar svg {
            fill: gray;   
            margin-right: 5px;
            font-size: 0.9rem;
        }

.childButton__3O3OZ .status__3AkSr {
        flex: 0.2;
        font-size: 2rem;
        display: flex;
        justify-content: center;
    }

.childButton__3O3OZ .status__3AkSr.done__UKXhS svg {
            fill: green;   
        }

.childButton__3O3OZ .status__3AkSr.block__mmNeC{
        font-size: 1rem;
    }

.childButton__3O3OZ .status__3AkSr.block__mmNeC div {
            border: solid 1px;
            border-radius: 50%;
            display: flex;
            padding: 5px
        }

.childButton__3O3OZ .status__3AkSr.block__mmNeC svg {
            fill: red;   
        }

.childButton__3O3OZ .status__3AkSr.current__38j-Z{
        font-size: 1.5rem;
    }

.childButton__3O3OZ .status__3AkSr.current__38j-Z div {
            border: solid 1px;
            border-radius: 50%;
            display: flex;
            padding: 5px
        }

.childButton__3O3OZ .status__3AkSr.current__38j-Z svg {
            fill: black;
        }

.childButton__3O3OZ .status__3AkSr.done__UKXhS.wrongAnswer__2V8aL svg {
            fill: red;   
        }

.topicInfoWrapper__3Htli{
    flex: 1;
}

.topicInfoWrapper__3Htli h1 {
        color: #CC298E;
    }

.topicInfoWrapper__3Htli p{
        font-size: 0.89rem;
        font-weight: lighter;
        color: black;
    }

.topicControl__zJyKj{
    flex:0.1;
    display: flex;
    justify-content: flex-end;
}

.topicControl__zJyKj svg {
        fill: black;
    }

.cardBlocked__YmJ8s {
    position: relative;
  }

.cardBlocked__YmJ8s h1, .cardBlocked__YmJ8s p, .cardBlocked__YmJ8s svg {
        opacity: 0.5;
    }

.cardBlocked__YmJ8s:after {
    /* requerido */
    content: '';

    /* estilo de preto quase transparente */
    background-color: black;
    opacity: 0;

    /* posiciona nos limites do elemento pai */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* suavização básica */
    transition: 200ms all;
  }

.cardCurrent__7-UgI{
    background-color: transparent;

    border: double 2px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white), repeating-linear-gradient( 45deg, red, orange, yellow, green, blue, indigo, violet, red);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
.startDrawer__3VRIK {
  width: 100%;
  height: 500px;
  background-color: white;
  padding-top: 165px;
  padding-bottom: 15px;
  z-index: 1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

.miniDrawer__3uvI3 {
  height: 200px;
}

.close__OtGKE {
  margin-left: auto;
}

.marginInline24__1F5Xb {
  margin-inline: 24px;
}

.marginBlock12__1M6Rx {
  margin-block: 12px;
}

.marginTop36__2hIRS {
  margin-top: 36px;
}

.routesLine__1VNnY {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  overflow-x: overlay;
  margin-inline: 24px;
}

.centered__1d6k9 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.clickable__1fuMz {
  cursor: pointer;
  transition: 0.2s;
}

.clickable__1fuMz:hover {
    transform: scale(1.01);
  }

.searchBar__z82V7 {
  left: 0px;
  top: 0px;
  position: relative;
}

.placesContainer__3huaO {
  height: 70vh;
  margin-top: 24px;
}

.placesContainer__3huaO p {
    margin-bottom: 20px;
    color: #707070;
  }

.placesList__ZFq_S {
  width: 100%;
  height: 70vh;
  overflow-y: overlay;
}

.marginTopSearchBar__1mE5Z {
  padding-top: 75px;
}

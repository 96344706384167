.collaboratorCard__7v4lr {
  display: flex;
  flex-direction: row;
  align-items: center;
}

  .collaboratorCard__7v4lr img {
    max-width: 75px;
    border-radius: 100%;
  }

  .collaboratorCard__7v4lr section {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    padding-left: 12px;
  }

  .collaboratorCard__7v4lr section h4 {
      margin: 0;
      font-size: 16px;
    }

  .collaboratorCard__7v4lr section p {
      margin-block: 6px;
    }

.role__1bqCE {
  font-size: 14px;
  font-weight: 400;
  color: #787878;
}

.status__1Gc2O {
  font-size: 12px;
  font-weight: 600;

  display: flex;
  align-items: center;
}

.status__1Gc2O svg {
    margin-right: 8px;
  }

.finished__2J1D4 {
  color: #8D68DC;
}

.doing__YTQ4l {
  color: #F04742;
}

.removeButton__36hXP {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s;

  padding: 16px;
  font-size: 20px;
  border-radius: 100%;

  background-color: #FFF3F3;
  color: #E41D1D;
  cursor: pointer;
}

.removeButton__36hXP:hover {
    background-color: #e5d2d2;
  }

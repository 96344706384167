.hero__2SFyU {
  width: 130px;
}

.guideCard__1qbBJ {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.guideCard__1qbBJ img {
    max-width: 75px;
  }

.guideCard__1qbBJ section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
  }

.guideCard__1qbBJ section b {
      font-size: 0.9rem;
    }

.guideCard__1qbBJ section > p {
      margin-block: 6px;
      font-size: 0.8rem;
      font-weight: 500;
      color: #787878;
    }

.inline__1gf-r {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #cc298e;
}

.inline__1gf-r svg {
    margin-left: 8px;
  }

.knowMoreContent__1FsCd {
  color: #343434;
  text-align: center;
  font-size: 0.95rem;
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.container__2bfh- {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  width: 20%;
}

@media (max-width: 768px) {

.container__2bfh- {
    height: auto;
    width: 95%
}
  }

.knowMoreTitle__1SG61 {
  text-align: center;
  font-size: 1rem;
  margin-top: 20px;
}

.contentWrapper__we8wT {
  padding: 5px 15px;
  width: 24rem;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.contentWrapper__we8wT .section__2O6Au {
    margin-top: 15px;
    width: 100%;
  }

.contentWrapper__we8wT .section__2O6Au a {
      color: #125EED;
    }

.contentWrapper__we8wT .section__2O6Au p {
      margin-top: 5px;
      text-decoration: underline;
    }

.contentWrapper__we8wT .section__2O6Au .noLink__59NS5 {
      font-size: 0.75rem;
      color: black;
      text-decoration: none;
    }

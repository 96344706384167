.zoomControl__10ZMz {
  border: 1px solid #d0d0d0;
  border-radius: 66px;
}

.zoomButton__1b7GH {
  width: 66px;
  height: 52px;
  background-color: #ffffff;
  border-color: transparent;
  font-size: 1.5rem;
}

.zoomButton__1b7GH.increaseButton__3JSDI {
    border-top-left-radius: 66px;
    border-bottom-left-radius: 66px;
    border-right: none;
  }

.zoomButton__1b7GH.decreaseButton__36ki_ {
    border-top-right-radius: 66px;
    border-bottom-right-radius: 66px;
    border-left: none;
  }

.zoomButton__1b7GH:active {
    background-color: #f8f8f8;
  }

@media (max-width: 1000px) {
  .zoomControl__10ZMz {
    display: none;
  }
}

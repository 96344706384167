.spacedBetween__ZYgIi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.marginTop12__HUp1H {
  margin-top: 12px;
}

.marginBlock12__IeuUv {
  margin-block: 12px;
}

.grid__2nsBp {
  display: grid;
  grid-template-columns: 1fr 80px;
}

.padding12__1lJwq {
  padding: 24px 24px;
}

.rounded__J63Pj {
  border-radius: 12px;
}

.profileImage__3C_AW {
  height: 110px;
  align-self: center;
}

.column__2gEV3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
}

.noSelected__3wwHG {
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  font-size: 12px;
  border: solid 2px #027BFA;
  color: #027BFA;
}

.noSelected__3wwHG h2 {
    color: #027BFA;
    font-weight: 600;
  }

.noSelected__3wwHG:hover {
    background-color: #D4E3FF;
  }

.selected__3ODpK {
  background-color: #027BFA;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
}

.selected__3ODpK h2 {
    color: #ffffff;
    font-weight: 600;
  }

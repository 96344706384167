.coupon__1RNbc {
  background-color: #fff;
  position: relative;

  border: none;
  border-radius: 5px;

  overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bullet__2aVv0 {
  background-color: #D4E3FF;

  width: 48px;
  height: 48px;

  border-radius: 100%;

  position: absolute;

  top: 65px;
}

@media (max-width: 768px) {

.bullet__2aVv0 {
    width: 36px;
    height: 36px;
    top: 45px
}
  }

.container__1i_9u {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 2vh;
}

  .container__1i_9u h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    font-weight: 700;
    color: #000000;
  }

  @media (max-width: 768px), (max-width: 1366px) and (max-height: 768px) {.container__1i_9u {
    font-size: 0.8rem
}
  }

.sponsors__djYfT {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.sponsors__djYfT img {
    width: auto;
    height: 150px;
    margin: auto;
  }

.sponsors__djYfT img:not(:last-child) {
      margin-right: 10px;
    }

@media (max-width: 768px), (max-width: 1366px) and (max-height: 768px) {
    .sponsors__djYfT img {
      width: 105px;
      height: auto;
    }
  }

.partners__lVhGv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

}

.partners__lVhGv img {
    width: auto;
    height: 45px;
    margin: auto;
  }

.partners__lVhGv img :not(:last-child) {
      margin-right: 32px;
    }

@media (max-width: 768px), (max-width: 1366px) and (max-height: 768px) {
    .partners__lVhGv img {
      height: 32px;
    }

      .partners__lVhGv img:not(:last-child) {
        margin-right: 12px;
      }
  }

.imgWrapper__B7fRR {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 24px;
  width: 100%;
}

@media (max-width: 768px) {

.imgWrapper__B7fRR {
    flex-wrap: nowrap;
    overflow: overlay
}
  }

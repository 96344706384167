.wrapper__17xgd{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageWrapper__3eo_L{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}

@media (max-width: 768px) {
        .imageWrapper__3eo_L img{
            width: 100%;
        }   
    }

.optionsWrapper__TdQsu{
    display: flex;
    flex-direction: column;
}

.title__1iBg7{
    width: 25rem;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
    align-self: center;
}

.subTitle__2zu1e{
    align-self: center;
    text-align: center;
    font-size: 1.125rem;
}

.contentWrapper__1CfCj{
    padding: 5px;
    width: 24rem;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.contentWrapper__1CfCj .cardWrapper__bUgPG{
        display: flex;
        align-items: center;
        justify-content:center;
        border-radius: 10px;
        box-shadow: 1px 2px 3px 3px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        padding: 20px;
    }

.contentWrapper__1CfCj .iconWrapper__3ZZue{
        flex:0.2;
    }

.contentWrapper__1CfCj .infoWrapper__3S1YR{
        flex:1;
    }

.contentWrapper__1CfCj .infoWrapper__3S1YR .answers__194c5{
            font-weight: bold;
        }

.contentWrapper__1CfCj .infoWrapper__3S1YR .answers__194c5 span{
                font-weight: 100;
            }

.contentWrapper__1CfCj .infoWrapper__3S1YR .green__3IRr4{
            color: #035A23;
        }

.contentWrapper__1CfCj .infoWrapper__3S1YR .pink__1tl23{
            color: #E38ACA;
        }

.contentWrapper__1CfCj .infoWrapper__3S1YR .pink__1tl23 svg{
                fill: #E38ACA;
            }

.contentWrapper__1CfCj .infoWrapper__3S1YR .red__2zc_A{
            color: #F04742;
        }

.contentWrapper__1CfCj .infoWrapper__3S1YR .rightAnswers__3FFCR{
            font-weight: bold;
            margin: 5px 0px 10px 0px;
        }

.contentWrapper__1CfCj .infoWrapper__3S1YR .message__gSRXR{
            color: black;
        }

.buttonWrapper__iC1wF {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }

.buttonWrapper__iC1wF :nth-child(1){
        margin-right: 15px;
    }

.graffitiFooter__1C67b {
    position: fixed;
    width: 30rem;
    bottom: -9vh;
    left: -3vw;
  }
.container__171sZ {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  width: 95%;
}

  @media (max-width: 768px) {.container__171sZ {
    height: auto
}
  }

.inviteTitle__2NLkF {
  color: #000000;
  margin: 0 0 15px 0;
  font-weight: 600;
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 768px) {

.inviteTitle__2NLkF {
    margin: 20px 0
}
  }

.inviteContent__2LWDL {
  color: #343434;
  text-align: center;
  font-size: 1rem;
}

.inviteContent__2LWDL p {
    margin-bottom: 30px;
  }

@media (max-width: 576px) {

.inviteContent__2LWDL {
    font-size: 0.75rem
}
  }

.form__2mgsO {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.invitesInput__3wkfD {
  border: 1px solid #d1d1d1;
  text-align: center;
}

.invitesButton__2xR6n {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.footer__1ksQ2 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 0.75rem;
  font-weight: 500;
}

.footer__1ksQ2 svg {
    width: 12px;
  }

.footer__1ksQ2 a {
    text-decoration: underline;
    display: inline;
    font-size: 0.75rem;
  }

.successBtn__269Ae {
  margin-bottom: 1vh;
}

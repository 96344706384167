.establishments__3Rcvb {
    transform: translateY(105%);
    position: absolute;
    height: 280px;
    max-height: 337px;
    bottom: 20px;
    right: 0px;
    display: flex;
    width: 50vw;
    padding: 20px 20px 15px 20px;
    z-index: 6;
    cursor: grab;
    overflow: auto;
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-left: 35px;
    transition: transform 0.5s;
}

    .establishments__3Rcvb a {
        scroll-snap-align: start;
        box-sizing: border-box;
    }

    .establishments__3Rcvb.-isActive__E-gRd{
      transform: translateY(0);
    }

    .establishments__3Rcvb.-isInactive__YwzCV{
      transform: translateY(105%);
    }

    @media (max-width: 768px) {.establishments__3Rcvb {
        align-items: stretch;
        width: 100vw;
        bottom: 25px;
        height: unset
}
    }

    @media (max-width: 576px) {.establishments__3Rcvb {
      height: unset
}
    }

    .establishments__3Rcvb img {
        pointer-events: none;
    }

    .establishments__3Rcvb::-webkit-scrollbar {
      height: 6px;
      background-color: rgba(0, 0, 0, 0.1);;
      border-radius: 8px;

    }

    @media (max-width: 768px) {

    .establishments__3Rcvb::-webkit-scrollbar {
        display: none

    }
      }

    .establishments__3Rcvb::-webkit-scrollbar-thumb{
      background-color: #0146C7;
    }

    .establishments__3Rcvb:hover::-webkit-scrollbar-thumb{
      background-color: #0146C7;
    }

.section__1x38_ {
  margin-top: 15px;
  width: 100%;
}

  .section__1x38_ a {
    color: #125EED;
  }

  .section__1x38_ p {
    margin-top: 5px;
    text-decoration: underline;
  }

  .section__1x38_ .noLink__1jYjQ {
    font-size: 0.75rem;
    color: black;
    text-decoration: none;
  }

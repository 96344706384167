.modal__3B_H6 {
  width: 60%;
}

  @media (max-width: 768px) {.modal__3B_H6 {
    width: 50%
}
  }

  @media (max-width: 576px) {.modal__3B_H6 {
    width: 90%
}
  }

  .modal__3B_H6 h1 {
    margin-bottom: 30px;
  }

.modalContent__2jEJ4 {
  font-size: 0.75rem;
}

.modalContent__2jEJ4 > * {
  margin-bottom: 25px;
}

.labelWrapper__fzkyh {
  margin-bottom: 15px;
  text-align: left;
}

.label__1BcF7 {
  position: relative;
  font-weight: 600;
  color: #343434;
}

@media (max-width: 768px) {

.label__1BcF7 {
    font-size: 0.75rem
}
  }

.input__2mY5k {
  width: 100%;
  margin-top: 7px;
  padding: 10px 15px;
  border-radius: 15px;
  height: 45px;
  margin-bottom: 0;
  border: 1px solid #9a9a9a;
  outline: none;
}

@media (max-width: 768px) {

.input__2mY5k {
    height: 40px
}
  }

.error__8KXKy {
  border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.error__8KXKy::after {
    border-radius: 1px solid rgba(255, 0, 0, 0.6);
  }

.errorText__2FDjb {
  font-size: 0.6rem;
  color: red;
}

.checkbox__3vkWF {
  height: 20px;
  min-width: 20px;
  position: relative;
  margin-right: 10px;
}

.checkbox__3vkWF::after {
  content: "";
  display: inline-block;
  background: white;
  border: 1px solid #000;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 4px;
  cursor: pointer;
}

.checkbox__3vkWF::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 7px;
  height: 10.7px;
  border-right: 2.5px solid white;
  border-bottom: 2.5px solid white;
  top: 8.5px;
  left: 40%;
  transform: translate(-30%, -45%) rotate(40deg);
  font-size: 0.875rem;
  z-index: 2;
  opacity: 0;
}

.checkbox__3vkWF:checked::before {
  display: block;
  opacity: 1;
}

.checkbox__3vkWF:checked::after {
  background: #0146C7;
  border: 1px solid #0146C7;
}

.passwordBtn__1tcfP {
  float: right;
  margin-right: 15px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
  background-color: white;
}

.passwordBtn__1tcfP svg {
    color: gray;
  }

.radioWrapper__3EGFa {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.radioWrapper__3EGFa p {
    font-weight: normal;
  }

.radio__2OFgU {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #0146C7;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  margin-left: 10px;
  margin-right: 5px;
}

.radio__2OFgU:checked {
  background-color: #0146C7;
}

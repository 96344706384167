.title__w5RH1 {
  font-size: 1.5rem;
  font-weight: 800;
}

@media (max-width: 450px) {
  .title__w5RH1 {
    font-size: 1.5rem;
  }
}

.modal__2CbtF {
    width: 45vw;
    padding: 0 !important;
  }
  
  .mobileWrapper__L4SZd {
    position: fixed;
    z-index: 20;
    background: #fff;
    height: 100%;
    width: 100vw;
    bottom: 0;
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .findPlaces__1YP-p {
    height: 70%;
    z-index: 10;
  }
  
  .bePart__GMXQy {
    width: 70%;
  }
  
  .winRewards__3QYHg {
    height: 75%;
    max-width: 95%;
  }
  
  .winRewardsPin__1nede {
    height: 20%;
    position: absolute;
    right: 3vw;
    top: 27vh;
  }
  
  .findPlacesConffetti__9Y1OI{
    position: fixed;
    z-index: 0;
  }
  
  .loginWrapper__MRYT8 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 55vh;
    justify-content: space-around;
  }
.counter__3TktB{
    position: absolute;
    background: rgba(0,0,0,0.2);
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: white;
}
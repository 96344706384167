.container__1BLb2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 95vh;
}

  .container__1BLb2 h1 {
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 40px;
    margin-bottom: 10%;
  }

  @media (max-width: 576px) {

  .container__1BLb2 h1 {
      font-size: 0.8rem
  }
    }

.row__2luk3 {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-around;
}

.column__27mCR {
  flex: 1;
}

.btn__1jOIb {
  font-size: 0.8rem;
}

@media (max-width: 576px) {

.btn__1jOIb {
    font-size: 0.6rem
}
  }

.container__3lWli {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 80%;
  text-align: center;
  margin: 10px 20px;
}

.signUpModal__1srPO {
  height: 80vh;
}

.marginBlock12__2oPzb {
  margin-block: 12px;
}

.centeredContainer__llHTi {
  justify-content: center;
  align-items: center;
}

.placeImage__10iRX {
  width: 230px;
  height: 170px;
}

.signupDataField__3WpoV {
  margin: auto;
}

.fullWidth__3TahK {
  width: 100%;
}

.biggerField__3IPnL {
  flex: 2;
}

.smallerField__2S5ob {
  flex: 1;
}

.navBtn__6UKCv {
  color: #000;
  transition: box-shadow 300ms ease;
  width: 124px;
  margin-right: 20px;
}

.navBtn__6UKCv:last-of-type {
    margin-right: 0;
  }

.navBtn__6UKCv:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  }

.borderButton__178bD {
  color: #125EED;
}

.loader__2z20z {
  right: 50px;
}

.pagination__2euCS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: flex-end;
  width: 100%;
}

.detailsText__o-XqD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
}

.detailsText__o-XqD span {
    margin-inline: 4px;
  }

.detailsText__o-XqD span:first-child {
    margin-left: 0;
  }

.ratingText__3z_vZ {
  color: #125EED;
}

.padding25Percent__1wijB {
  padding-inline: 25%;
}

.blockContainer__2ccOX {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex: 1;
}

.checkboxSection__1Gxl4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 576px) {

.checkboxSection__1Gxl4 {
    width: 90%
}
  }

.checkboxErrorMessage__125-o {
  position: absolute;
  top: -1.3rem;

  color: white;
  font-weight: bolder;
  background-color: #d04945;
  border: none;
  font-size: 0.5rem;
  text-align: center;
  border-radius: 15px;
  padding: 4px 8px;
}

.checkboxWrapper__2U1t9 {
  position: relative;
  display: inline-flex;
  margin-top: 1.5rem;
}

.terms__3QFP1 {
  font-size: 0.75rem;
}

.checkboxError__FS8A- {
  color: red;
}

.padding24__2o3IF {
  padding-block: 24px;
}

.flexEnd__2rCJ5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.flexEnd__2rCJ5 button {
    width: 200px;
  }

.flexCenter__3Z0b8 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexCenter__3Z0b8 button {
    width: 200px;
  }

.successCard__1g9H_ {
  background-color: #eef4ff;
  border-radius: 12px;
}

.passwordStrength__1FQ88 {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.passwordStrength__1FQ88 svg path {
    fill: #e5e5e5;
  }

.passwordStrength__1FQ88 svg.checked__2VK0B path {
    fill: #1f734c;
  }

.passwordStrength__1FQ88 p {
    color: #605f5f;
    font-size: .75rem;
    margin-left: 0.3rem;
    text-align: left;
  }

.labelStack__dndQs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block: 12px;
}

.fontSize12__13bNr{
  font-size: 0.75rem
}

.analysisImg__2xOBo {
  height: 50%;
}

@media (max-width: 576px) {

.analysisImg__2xOBo {
    width: 100%
}
  }

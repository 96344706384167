.drawer__3sdXl {
  position: fixed;
  z-index: 10;
  margin-right: -1px;
}

.menu__3r6Lz {
  position: fixed;
  top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 45vw;
  z-index: 4;
  padding: 0 40px 0 30px;
}

@media (max-width: 768px){

.menu__3r6Lz {
    width: 100%;
    padding: 0 20px
}
  }

.closeButton__19Djb {
  color: #ffffff;
  background-color: transparent;
  width: 14px;
  margin-top: 2px;
}

.top__1Teft {
  top: 0;
  animation: mountTop__3NpPv 500ms;
}

.top__1Teft.close__hLx5w {
    transform: translateY(-110%);
    animation: unmountTop__1imcJ 500ms;
  }

.right__23Twu {
  top: 0;
  right: 0;
  animation: mountRight__3XfET 500ms;
}

.right__23Twu.close__hLx5w {
    transform: translateX(110%);
    animation: unmountRight__181LL 500ms;
  }

.bottom__CFvuN {
  bottom: 0;
  animation: mountBottom__1ELUk 500ms;
}

.bottom__CFvuN.close__hLx5w {
    transform: translateX(110%);
    animation: unmountBottom__3vZvJ 500ms;
  }

.left__3nnD6 {
  top: 0;
  left: 0;
  animation: mountLeft__1vSFw 500ms;
}

.left__3nnD6.close__hLx5w {
    transform: translateX(-110%);
    animation: unmountLeft__XKpFL 500ms;
  }

@keyframes unmountTop__1imcJ {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-110%);
  }
}

@keyframes mountTop__3NpPv {
  0% {
    transform: translateY(-110%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes mountRight__3XfET {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes unmountRight__181LL {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes unmountBottom__3vZvJ {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(110%);
  }
}

@keyframes mountBottom__1ELUk {
  0% {
    transform: translateY(110%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes unmountLeft__XKpFL {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-110%);
  }
}

@keyframes mountLeft__1vSFw {
  0% {
    transform: translateX(-110%);
  }

  100% {
    transform: translateX(0%);
  }
}

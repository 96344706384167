.ripple__2PZvH {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}

  .ripple__2PZvH > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: ripple__2PZvH 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .ripple__2PZvH > div:nth-child(2) {
      animation-delay: -0.5s;
    }

@keyframes ripple__2PZvH {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    width: 42px;
    height: 42px;
    opacity: 0;
  }
}

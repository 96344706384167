.search__2yRF3 {
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.marker__27POU {
  position: absolute;
  width: 20px;
}

.button__RCX3f,
.seachBox__ZTS-N {
  position: absolute;
}

.button__RCX3f {
  z-index: 10;
  background-color: #0146C7;
  width: 50px;
  height: 50px;
  padding: 16px 14px;
  margin-left: 3px;
}

.button__RCX3f > div {
    height: 2px;
    width: 22px;
    border-radius: 1px;
    background-color: #ffffff;
  }

.link__1LwHE {
  display: flex;
  flex-direction: row;
  color: #0146C7;

  font-weight: 500;
  font-size: 0.65rem;
}

  .link__1LwHE svg {
    height: 12px;
    margin-right: 5px;
    margin-top: 1px;
  }

  .link__1LwHE svg path {
      stroke: #0146C7;
    }

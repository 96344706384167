.filters__34uF9 {
  display: flex;
  flex-direction: row;
  overflow-x: overlay;
  margin-bottom: 8px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}  /* Firefox */  .filters__34uF9 > * {
    margin-left: 5px;
  }

.filters__34uF9::-webkit-scrollbar {
  display: none; /* hides scollbar */
}

.friendly__3ZQD6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 125px;
}

.marker__kxENM {
  height: 15px;
}

.filter__dsXA1 {
  display: none;
  position: absolute;
  z-index: 10;
}

.showFilter__3Sv8N {
  display: block;
}

.types__1H5SW {
  width: 165px;
}

.types__1H5SW,
.prices__Ny78F,
.ratings__2HEhV {
  font-weight: bold;
  margin-bottom: 5px;
}

.prices__Ny78F,
.ratings__2HEhV {
  width: 140px;
}

@media(max-width: 768px){
    .bottomSheet__2MLFm ul {
        display: grid;
        grid-template-columns: 50% 50%;
        height: auto;
    }
  }

.wrapper__a76_p {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
  margin-bottom: 20px;
}

.close__1lP1Q {
  position: absolute;
  z-index: 10000;
  right: 15px;
  top: 15px;
  border: none;
}

.pagesControl__1d3sL {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.buttonWrapper__2evsL {
  width: 100%;
}

.leftBtn__S79VT {
  display: flex;
  justify-content: flex-start;
}

.rightBtn__3H1x_ {
  display: flex;
  justify-content: flex-end;
}

.pageBtn__3wfEE {
  display: flex;
  align-items: center;

  color: #0146C7;
  font-weight: bold;
  background: inherit;
  border: solid 1px #0146C7;
  border-radius: 40px;
  padding: 10px 20px;
}

.pageBtn__3wfEE svg {
    margin: 0 5px;
  }

.roundBtn__2fHJG {
  background-color: #0146C7;
  border-radius: 40px;
  color: white;
  padding: 10px 20px;
}

.dots__35l_E > li > div::before {
    background: rgba(150, 150, 150, 1) !important;
  }

.dots__35l_E > li > div[class*="selected"]::before {
    background: #1F00B1 !important
  }

@media (max-width: 768px){

.bigBtn__Quj71 {
    width: 180px
}
  }

.centerBackBtn__2boeH {
  justify-content: flex-end;
  margin-right: 2rem;
}

.centerNextBtn__2WKgV {
  justify-content: flex-start;
}

.centerBtn__38jez {
  justify-content: center;
}

#google-map {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 0;
}

.gm-style img {
  max-width: 100%;
}

.cluster {
  transform: translateY(-50%);
}

.controls {
  z-index: 2;
  transition: background-color 300ms ease;
}

.top-left {
  z-index: 5;
  position: fixed;
  top: 25px;
  left: 25px;
  align-self: flex-start;
  display: flex;
}

.top-left > :not(:last-child) {
    margin-right: 25px;
  }

@media (max-width: 768px) {

.top-left {
    top: 10px;
    left: 10px;
    width: calc(100vw - 20px)
}

    .top-left > :not(:last-child) {
      margin-right: 10px;
    }
  }

.bottom-right {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  right: 5vw;
  bottom: 5vh;
  align-self: flex-end;
}

.bottom-right > :not(:last-child) {
    margin-right: 25px;
  }

.places {
  background-color: #8368d8;
}

.level__8JJIv {
  padding: 5px;
  border: 2px solid #EFF0F2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 90px auto 80px;
  align-items: center;
  min-height: 110px;
  height: auto;
  background-color: #ffffff;
  cursor: pointer;
  min-width: 310px;
}

  @media(max-width: 576px){.level__8JJIv {
    grid-template-columns: 80px auto 60px;
    min-width: 180px
}
  }

.description__3fCWP {
  text-align: left;
  padding: 0px 15px 0px 15px;
}

.description__3fCWP p {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 3px;
  }

.description__3fCWP h3 {
    color: #B3B6BF;
    max-width: 115px;
  }

@media(max-width: 768px) {

.description__3fCWP {
    padding: 0px 5px
}
  }

.exchangeValue__j1Hnf {
  border-left: 2px solid #EFF0F2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exchangeValue__j1Hnf strong {
    font-size: 1.25rem;
  }

.exchangeValue__j1Hnf p {
    font-size: 0.75rem;
  }

.inactive__2A3lW {
  border: 2px solid #B3B6BF;
}

.inactive__2A3lW img {
    filter: grayscale(100%);
  }

.inactive__2A3lW .exchangeValue__j1Hnf {
    color: #B3B6BF;
  }

.link__1b09e {
  display: flex;
  flex-direction: row;
  color: #0146C7;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 10px;
}

.link__1b09e svg {
    width: 10px;
    margin-left: 5px;
    fill: #0146C7;
    transform: scaleX(-1);
    margin-top: 3px;
  }

.active__1zzNY {
  color: #02D78A;
}

.active__1zzNY svg {
    fill: #02D78A;
  }

.display__r93tY {
  display: none;
}

.hero__23MT7 {
  position: relative;
  width: 85%;
  overflow: hidden;
  height: 300px;
  min-height: 300px;
  background-position: center;
  background-size: cover;
  background-color: gray;
}

  @media (max-width: 768px) {.hero__23MT7 {
    height: 250px;
    min-height: 250px
}
  }

.logo__1N1ej {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  min-height: 124px;
  border-radius: 100%;
  background-color: #ffffff;
}

  .logo__1N1ej svg {
    margin: auto;
    width: 80%;
  }

  @media (max-width: 768px), (max-height: 768px) {.logo__1N1ej {
    width: 180px;
    min-height: 180px
}
  }

  @media screen and (max-width: 1366px) {.logo__1N1ej {
    min-height: 0
}
  }

  @media (max-width: 576px) {.logo__1N1ej {
    width: 150px;
    min-height: 150px
}
  }

.container__2ZDMW {
  margin: 20px auto;
  overflow: overlay;
  height: 50vh;

  flex: 1;
  width: 95%;
}

.cardContainer__EXUOt {
  background-color: #fff;
  border-radius: 23px;
  direction: ltr;
  margin-bottom: 20px;

  padding: 3% 5%;

  overflow-y: overlay;
  overflow-x: hidden;

  max-width: 90%;
}

.cardContainer__EXUOt hr {
    margin-block: 12px;
  }

@media (max-width: 768px) {

.cardContainer__EXUOt {
    max-width: 100%
}
  }

.almost-closed__3tFvm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.almost-closed__3tFvm p {
    margin-left: 12px;
    font-size: 12px;
    color: #F9D026;
  }

.closed__2dan8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.closed__2dan8 p {
    margin-left: 12px;
    font-size: 12px;
    color: #D04945;
  }

.opened__3FX89 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.opened__3FX89 p {
    margin-left: 12px;
    font-size: 12px;
    color: #2DA66E;
  }

.always-opened__EUg4b {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.always-opened__EUg4b p {
    margin-left: 12px;
    font-size: 12px;
    color: #1cc979;
  }

.description__1YqrR {
  color: #999999;
  font-size: 13px;
}

.link__1qGz_ {
  text-decoration: underline;
  color: #125EED;
}

.bold__2L9iH {
  font-weight: 600;
}

.contacts__2fx_l {
  display: flex;
  flex-direction: column;
  color: #125EED;
}

.contacts__2fx_l a {
    margin-block: 4px;
  }

.inline__1kJD1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.typeDescription__1MymI {
  border: 1px solid #e6e6e6;
  border-radius: 12px;

  padding: 12px;
}

.addressDescription__usQyc {
  font-size: 13px;
  margin-top: 6px;
  margin-right: 4px;
}

.galleryPhoto__3qBUy {
  height: 100px;
  min-width: 70px;
  border-radius: 12px;

  margin-inline: 4px;
  cursor: pointer;
}

.gallery__3FHHh {
  max-width: 100%;
  overflow-x: overlay;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.aboutPlaceHeader__24oBQ {
  margin-bottom: 12px;
}

.establishmentCard__1uC_6 {
  background-color: white;
  width: 180px;
  max-width: 250px;
  border-radius: 15px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  padding: 0;
  text-align: left;
  color: #000;
  margin-right: 20px;
}

  @media (max-width: 768px) {.establishmentCard__1uC_6 {
    flex-direction: row;
    padding: 16px 17px;
    gap: 22px;
    height: unset;
    max-width: 300px;
    width: 317px;
    align-items: center
}
  }

.establishmentCard__1uC_6.-active__1KvFK {
  transform: scale(1.07);
  border: 5px solid #0146C7;
}

.establishmentImage__1ANB5 {
  width: 100%;
  height: 50%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media (max-width: 768px) {

.establishmentImage__1ANB5 {
    height: 90px;
    width: 90px;
    border-radius: 15px
}
  }

.cardContent__QJ4jw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15% 8% 10%;
  overflow: auto;
  flex-grow: 1;
}

.cardContent__QJ4jw h1 {
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

@media (max-width: 768px) {

.cardContent__QJ4jw {
    padding: 0;
    height: 100%;
    gap: 6px
}
  }

.ratingMarker__3TYMp {
  height: unset;
  width: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 768px) {

.ratingMarker__3TYMp {
  width: 24px;
    position: static;
    transform: unset
}
  }

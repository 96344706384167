/* Customize the label (the container) */
.radioWrapper__12XAN {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 75px;
  width: 200px;
}
@media(max-width: 576px) {
.radioWrapper__12XAN {
    height: 50px;
    width: 150px
}
  }
/* Hide the browser's default radio button */
.radioWrapper__12XAN input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark__jCilG {
  position: absolute;
  height: 75px;
  width: 200px;
  background-color: transparent;
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.checkmark__jCilG:hover {
    border: 1px solid #0146C7;
  }
@media(max-width: 576px) {
.checkmark__jCilG {
    height: 50px;
    width: 150px
}
  }
/* When the radio button is checked, add a blue background */
.radioWrapper__12XAN input:checked ~ .checkmark__jCilG {
  border: 1px solid #0146C7;
}
.label__2YpM0 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1em;
  font-weight: 600;
  white-space: normal;
  color: black;
  text-align: center;
}

.helpContainer__3g1Pw {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper__1mcIm {
  width: 100%;
  margin-top: 20px;
}

.wrapper__1mcIm h1 {
    color: #343434;
    font-size: 1.2rem;
  }

.wrapper__1mcIm > * {
    margin-bottom: 20px;
  }

.result__1Uim1 {
  overflow: overlay;
  height: 55vh;
  width: 100%;
}

.helpSearch__3s-_T {
  color: #0146C7;
  border-bottom: 1px solid #0146C7;
}

.helpSearchWrapper__2GV_p {
  border: none;
}

.helpSearchWrapper__2GV_p svg path {
      fill: #0146C7;
    }

.helpSearchWrapper__2GV_p input {
    color: #0146C7;
  }

.helpSearchWrapper__2GV_p :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #0146C7;
    opacity: 1; /* Firefox */
  }

.helpSearchWrapper__2GV_p ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #0146C7;
    opacity: 1; /* Firefox */
  }

.helpSearchWrapper__2GV_p :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #0146C7;
  }

.helpSearchWrapper__2GV_p ::-ms-input-placeholder { /* Microsoft Edge */
    color: #0146C7;
  }

.options__1ncMK {
  border: 1px solid  #0146C7;
  color: #343434;
  padding: 8px 15px;
  margin-right: 15px;
  white-space: nowrap;
}

.optionsWrapper__1ALpj {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.active__sH-HH {
  background-color: #EEF4FF;
}

.icons__3Y1pv {
  border: 1px solid  #0146C7;
  color: #343434;
  width: 150px;
  margin-top: 10px;
  margin-right: 10px;
}

.icons__3Y1pv img {
    height: 100px;
  }

.thumbsActive__lEoMT svg {
    fill: #0146C7;
  }

/* -------------------------------------------------- */

/* ------------------- FAQ part --------------------- */

/* -------------------------------------------------- */

.faqWrapper__yQprE {
  margin-bottom: 20px;
}

.faqWrapper__yQprE h2 {
    margin-bottom: 10px;
  }

.answerFooter__1zlN0 {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.answerFooter__1zlN0 > * {
    margin-right: 10px;
  }

.accordion__SPei7 {}

.accordionItem__19ziM {
  border-radius: 8px;
  border: 1px solid #EFF0F2;
  padding: 0px 10px;
}

.accordionItem__19ziM + .accordionItem__19ziM {
  margin-top: 2vh;
}

.accordionButton__1fjlp  {
  display: grid;
  grid-template-columns: 90% 10%;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  background-color: #ffffff;
  font-size: 0.8rem;
  padding: 20px 0 20px 10px;
}

.accordionButton__1fjlp:after {
  display: inline-block;
  content: '';
  height: 7px;
  width: 7px;
  margin-left: 12px;
  margin-top: 12px;
  border-bottom: 2px solid #D8DADE;
  border-right: 2px solid #D8DADE;
  transform: rotate(45deg);
  transition-duration: 0.8s;
  transition-property: transform;
}

.accordionButton__1fjlp:hover:after {
  border-bottom: 2px solid #0146C7;
  border-right: 2px solid #0146C7;
}

.accordionButton__1fjlp[aria-expanded='true']::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #0146C7;
  border-right: 2px solid #0146C7;
}

.accordionButton__1fjlp[aria-selected='true']::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #0146C7;
  border-right: 2px solid #0146C7;
}

.accordionPanel__2evI9 {
  padding: 20px 10px;
  animation: fadein__3mRs1 0.35s ease-in;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: #4D4D4D;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  border-top: 1px solid #999999;
}

.accordionPanel__2evI9 p {
    margin-bottom: 10px;
  }

.accordionPanel__2evI9 a {
    color: #000000;
  }

@keyframes fadein__3mRs1 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

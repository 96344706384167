.drop_container__3Is29 {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    background-color: #EEF4FF;
  }

    .drop_container__3Is29 svg{
        font-size: 3rem;
        fill: #125EED;
    }

    .drop_container__3Is29 input{
        display: none;
    }
  
  .drop_container__3Is29:hover {
    background: #eee;
    border-color: #111;
  }
  
  .drop_container__3Is29:hover .drop-title__3uVln {
    color: #222;
  }
  
  .drop_title__3wp5o {
    color: #444;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
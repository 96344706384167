.brandingImage__3CElZ {
  width: 9rem;
  margin-right: 1rem;
}

.footerText__19zJC {
  font-size: 0.8rem;
  color: #4c4c4c;
  line-height: 20px;

  margin-left: 35px;
  margin-top: 3rem;
}

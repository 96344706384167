h1.header__1uPQL {
  color: black;
  font-size: 1.5rem;
}

h2.header__1uPQL {
  color: #343434;
  font-size: 1rem;
}

h3.header__1uPQL {
  color: #4C4C4C;
  font-size: 0.75rem;
}

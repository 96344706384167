.loaderWrapper__1LybJ {
  display: flex;
  width: 65px;
  height: 65px;
  justify-content: center;
  align-items: center;
}

.spinner__3rJwc {
  animation: rotator__2zn3o 1.4s linear infinite;
  height: 100%;
}

.path__1liZe {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
}

@keyframes rotator__2zn3o {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.default__2-3m9 {
  animation: dash__25CiG 1.4s ease-in-out infinite, colors__3ySPj 5.6s ease-in-out infinite;
}

.bright__3qNDu {
  animation: dash__25CiG 1.4s ease-in-out infinite, brightColors__3g6ZH 5.6s ease-in-out infinite;
}

@keyframes brightColors__3g6ZH {
  0% {
    stroke: #f7c223;
  }
  50% {
    stroke: #de3e35;
  }
  100% {
    stroke: #f7c223;
  }
}

@keyframes colors__3ySPj {
  0% {
    stroke: hsl(0, 80%, 60%);
  }
  25% {
    stroke: hsl(60, 80%, 60%);
  }
  50% {
    stroke: hsl(120, 80%, 60%);
  }
  75% {
    stroke: hsl(180, 80%, 60%);
  }
  100% {
    stroke: hsl(240, 80%, 60%);
  }
}

@keyframes dash__25CiG {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.wrapper__J7tbh{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageWrapper__24uQa{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.imageWrapper__24uQa img{
        max-width: 100%;
    }

.optionsWrapper__2HiLH{
    display: flex;
    flex-direction: column;
}

.title__P1fld{
    max-width: 25rem;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    margin: 30px 0px;
    align-self: center;
}

.subTitle__1dWYY{
    align-self: center;
    text-align: center;
    max-width: 25rem;
}

.contentWrapper__2lLEz{
    padding: 5px;
    width: 24rem;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contentWrapper__2lLEz .section__1thU4{
        margin: 25px 25px 0px 0px;        
    }

.graffitiFooter__2MlpG {
    position: fixed;
    width: 30rem;
    bottom: -9vh;
    left: -3vw;
  }

.moduleNumberWrapper__1pCEm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:0.1;
    margin-right: 10px;
    position: relative;
}

.moduleNumberWrapper__1pCEm img {
        border-radius: 50%;
    }

.moduleNumberWrapper__1pCEm span {
        position: absolute;
        background-color: white;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        color: #E38ACA;
        display: flex;
        justify-content: center;
        align-items: center;
    }

.answersWrapper__lN1Ws{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.answersWrapper__lN1Ws .correctAnswerWrapper__1D0-L{
        background-color: #3AD68E;
        border-radius: 50%;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        color: white;
    }

.answersWrapper__lN1Ws .wrongAnswer__MsIqL{
        background-color: red;
    }

.buttonsWrapper__33lVN{
    align-self: center;
    display: flex;
    justify-content: space-evenly;
}

.buttonsWrapper__33lVN button {
        margin: 10px 10px 0px 10px;
    }
.wrapper__F3QQ1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 90vh;
}

  @media (max-width: 576px){.wrapper__F3QQ1 {
    width: 95%
}
  }

.coupon__Y9t0h {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border: 1.5px solid #D8DADE;
  border-radius: 8px;
  padding: 8px 30px 8px 30px;
  min-height: 100px;
  height: 15vh;
}

.coupon__Y9t0h p {
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 100%;
    color: #343434;
  }

.coupon__Y9t0h img {
    width: 90px;
  }

.login__1UMVQ {
  color: #0146C7;
  font-weight: bold;
  font-size: 0.75rem;
}

.login__1UMVQ svg {
    width: 9px;
    fill: #0146C7;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }

.couponInfo__14vSP{
  height: 65vh;
  width: 100%;
  text-align: left;
}

.couponInfo__14vSP h3 {
  font-size: 0.65rem;
  line-height: 100%;
  color: #343434;
  font-weight: normal;
  margin-bottom: 5px;
}

.couponInfo__14vSP h2 {
  font-weight: bold;
  font-size: 1rem;
  color: #343434;
  margin-bottom: 10px;
  margin-top: 20px;
}

.couponInfo__14vSP p {
  font-size: 0.75rem;
  line-height: 150%;
  color: #343434;
}

.couponInfo__14vSP li {
  font-size: 0.75rem;
  line-height: 150%;
  color: #343434;
}

.couponCode__2FPN5 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  border-top: 1px solid #D8DADE;
  border-bottom: 1px solid #D8DADE;
  margin-bottom: 10px;
}

.couponCode__2FPN5 span {
  font-size: 0.75rem;
  font-weight: 600;
  color: #343434;
  margin-right: 5px;
}

.center__irm1e {
  text-align: center;
  margin: 0 auto;
}

.themeColorLink__28I89 {
  color: #0146C7;
  font-size: 0.65rem;
  font-weight: 500;
}

.themeColorLink__28I89 svg {
    width: 12px;
    stroke: #0146C7;
  }

.blackLink__1HOTW {
  color: #000000;
  font-size: 0.65rem;
  font-weight: bold;
}

.couponModal__hrQyP {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30vh;
}

.backButton__hn35h {
  color: #ffffff;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  width: 16px;
  z-index: 4;
  left: 0;
  border: 0;
}

.shadow__FFY3H {
  filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.6));
  -webkit-filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.6));
}

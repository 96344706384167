.ratingWrapper__2zhwj {
  height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 7px;
}

.starRatings__1n6Ie {
  height: 20px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.starRatings__1n6Ie svg {
    width: 9.6px;
  }

.starRatings__1n6Ie > svg + svg {
    margin-left: 3px;
  }

.starRatings__1n6Ie span {
    width: 100%;
    margin: 1px 5px 0 5px;
  }

.-establishmentCard .ratingWrapper__2zhwj {
    align-items: flex-end;
  }

.-establishmentCard .starRatings__1n6Ie h1 {
      margin: 0 3px;
      font-size: 16px;
    }

.-establishmentCard .starRatings__1n6Ie svg {
      width: 17px;
    }

@media (max-width: 576px) {

.-establishmentCard .starRatings__1n6Ie svg {
        width: 13px
    }
      }

.actionMenu__oqS-Z {
  position: relative;
  display: flex;
  flex: 0.2;
  justify-content: center;
  padding-top: 15px;
  width: 100%;
  background-color: #ffffff;
  flex-wrap: wrap;
}

  .actionMenu__oqS-Z > li + li {
    padding-left: 24px;
  }

  @media (max-width: 576px) {

  .actionMenu__oqS-Z > li + li {
      padding-left: 12px
  }
    }

  .actionMenu__oqS-Z a {
    float: left;
    display: inline-block;
    color: #D8DADE;
    text-align: center;
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .actionMenu__oqS-Z a:hover {
    color: #125EED;
    font-weight: 700;
  }

  .actionMenu__oqS-Z a.active__iAzlt {
    color: #125EED;
    font-weight: 700;
  }

  .actionMenu__oqS-Z a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    background: #000;
    transition: width 0.3s;
  }

  .actionMenu__oqS-Z a:hover:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    transition: width 0.3s;
  }

  .actionMenu__oqS-Z a.active__iAzlt:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    transition: width 0.3s;
  }

.disabled__3X3m1 {
  cursor: not-allowed;
}

.disabled__3X3m1 > a {
    cursor: not-allowed;
  }

.disabled__3X3m1 > a:hover {
    color: #D8DADE;
  }

.disabled__3X3m1 > a.active__iAzlt {
    color: #D8DADE;
  }

.disabled__3X3m1 > a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    background: #D8DADE;
  }

.disabled__3X3m1 > a:hover:after,
  .disabled__3X3m1 > a.active__iAzlt:after {
    all: initial;
  }

.item__2oiid {
  display: flex;
  align-items: flex-end;
}

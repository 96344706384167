.wrapper__2ADhX {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 90vh;
}

  .wrapper__2ADhX > * {
    margin-top: 15px;
  }

.text__2aBkA {
  font-size: 0.75rem;
  font-weight: 500;
  color: #343434;
  margin-top: 10px;
  margin-bottom: 15px;
}

.text__2aBkA p {
    margin-bottom: 5px;
    text-align: left;
  }

.text__2aBkA div p {
      font-size: 0.85rem;
      color: #4C4C4C;
      margin-bottom: 25px;
    }

.login__3kLLf {
  color: #0146C7;
  font-weight: bold;
  font-size: 0.75rem;
}

.login__3kLLf svg {
    width: 9px;
    fill: #0146C7;
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }

.couponWrapper__6nTVt {
  overflow: overlay;
  height: 65vh;
  width: 100%;
}

@media (max-width: 576px) {

.couponWrapper__6nTVt {
    width: 85vw
}
  }

.footer__yAxs3 a {
  margin-bottom: 5px;
}

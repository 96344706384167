.labelWrapper__rK5uZ {
  margin-bottom: 15px;
  text-align: left;
}

.labelWrapper__rK5uZ textarea {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #9a9a9a;
  padding: 20px;
  outline: none;
  resize: none;
  margin-top: 10px;
  font-size: 0.75rem;
}

.label__1KhBH {
  position: relative;
  font-weight: 600;
  color: #343434;
}

@media (max-width: 768px) {

.label__1KhBH {
    font-size: 0.75rem
}
  }

.error__3q8o9 {
  border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.error__3q8o9::after {
    border-radius: 1px solid rgba(255, 0, 0, 0.6);
  }

.errorText__3iWUg {
  font-size: 0.6rem;
  color: red;
}

.image__3ts88 {
  max-width: 175px;
  margin-right: 24px;
}

  @media (max-width: 992px) {.image__3ts88 {
    margin-right: 0
}
  }

  @media (max-width: 768px) {.image__3ts88 {
    max-width: 100px
}
  }

.bigSize__vOvpD {
  font-size: 16pt;
}

@media (max-width: 768px) {

.bigSize__vOvpD {
    font-size: 13pt
}
  }

.inlineButton__3vz3Z {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inlineButton__3vz3Z span {
    margin-left: 12px;
  }

.marginBlock12__2Prcg {
  margin-block: 12px;
}

.spacedBetween__2mjnP {
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: space-between;
}

.couponDescription__27u-L {
  font-weight: 400;
  font-style: italic;
}

.deleteButton__9NgtH {
  font-size: 16px;
}

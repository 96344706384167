.reviewThankYouModal__2aZli {
  background: #fdf7fb;
  padding: 0;
  overflow: unset;
}

  .reviewThankYouModal__2aZli > div {
    background: #fdf7fb;
  }

.pointSystemInfo__3yV7e {
  font-size: 0.6rem;
  margin-top: 5%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.pointSystemInfo__3yV7e > p >svg {
    margin-right: 0.3rem;
  }

.pointSystemInfo__3yV7e > p > span {
    text-decoration: underline;
  }

.grateful__3n56p {
  margin-bottom: 20px;
}

.helpMore__1zzph {
  margin-top: 5%;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 20px;
  outline: none;
  color: #fff;
  background-color: #0146C7;
}

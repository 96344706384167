.modal__1hvA_ {
  width: 45vw;
}

  .modal__1hvA_ img {
    max-width: 250px;
  }

  .modal__1hvA_ p {
    color: #4C4C4C;
  }

  @media(max-width: 768px) {.modal__1hvA_ {
    width: 80vw
}
  }

.marginBlock12__2uKJi {
  margin-block: 12px;
}

.column__1W8wI {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link__3oMsz {
  color: #125EED;
  text-decoration: underline;
}

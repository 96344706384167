.wrapper__Dvhqe {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 90vh;
}

.faqContainer__2FniW {
  height: 80vh;
  overflow: overlay;
}

.accordion__1diA_ {}

.accordionItem__2BAuO {
  border-radius: 8px;
  border: 1px solid #EFF0F2;
}

.accordionItem__2BAuO + .accordionItem__2BAuO {
  margin-top: 2vh;
}

.accordionButton__2nN45  {
  display: grid;
  grid-template-columns: 90% 10%;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  background-color: #ffffff;
  font-size: 0.8rem;
  height: 10vh;
  padding: 2vh 0 2vh 2vh;
}

.accordionButton__2nN45:after {
  display: inline-block;
  content: '';
  height: 7px;
  width: 7px;
  margin-left: 12px;
  border-bottom: 2px solid #D8DADE;
  border-right: 2px solid #D8DADE;
  transform: rotate(45deg);
  transition-duration: 0.8s;
  transition-property: transform;
}

.accordionButton__2nN45:hover:after {
  border-bottom: 2px solid #125EED;
  border-right: 2px solid #125EED;
}

.accordionButton__2nN45[aria-expanded='true']::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #125EED;
  border-right: 2px solid #125EED;
}

.accordionButton__2nN45[aria-selected='true']::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #125EED;
  border-right: 2px solid #125EED;
}

.accordionPanel__3Sn38 {
  padding: 20px;
  animation: fadein__2QzW9 0.35s ease-in;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  color: #4D4D4D;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  border-top: 1px solid #EFF0F2;
}

.accordionPanel__3Sn38 p {
    margin-bottom: 10px;
  }

.accordionPanel__3Sn38 a {
    color: #000000;
  }

/* -------------------------------------------------- */

/* ---------------- Animation part ------------------ */

/* -------------------------------------------------- */

@keyframes fadein__2QzW9 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

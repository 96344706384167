.pageContainer__2Lyb6 {
    overflow-y: overlay;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 90vh;
    margin-top: 20px;
  }

    .pageContainer__2Lyb6 > * {
      margin-bottom: 20px;
    }

.radioContainer__35-lQ{
  display: flex;
  flex-direction: column;
  align-items: start;
}

.commentWrapper__forpn{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: auto;
}

.underThreeError__3rajC {
  text-align: center;
  width: 70%;
  margin: auto;
  font-size: 0.7em;
  color: red;
}

.btnSubmitWithError__1XY8v {
  margin-bottom: 5px !important;
}

.modalContent__1p5SS {
  text-align: center;
  padding: 20px;
}

.modalContent__1p5SS img {
    width: 150px;
  }

.modalContent__1p5SS .delete__CA69o {
    width: 100%;
  }

.modalContent__1p5SS > * {
    margin-bottom: 10px;
  }

@media (max-width: 768px) {

.modalContent__1p5SS {
    width: 80%
}
  }

.menuIcon__nVF09 svg {
    width: 1.2rem;
  }

.orange__U5GKj path {
    stroke: #F84B00;
  }

.green__--YDb path {
    stroke: #035A23;
  }

.purple__1aRuz path {
    stroke: #9A2481;
  }

.blue__3UMYV path {
    stroke: #125EED;
  }

.red__3gQAm path {
    stroke: #F50114;
  }

.pink__1OJ-9 path {
    stroke: #F86DB9;
  }

.container__2LI76 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.searchBar__1uW9D {
  left: 0px;
  top: 0px;
  position: relative;
}

.banner__k5wTu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1vw;
}

.banner__k5wTu .hero__1DF6d {
    width: 120px;
  }

@media (max-width: 576px){
    .banner__k5wTu .hero__1DF6d {
      width: 120px;
    }
  }

.bannerText__1FKTO{
  font-size: 0.8rem;
  color: #343434;
  line-height: 20px;
}

@media (max-width: 576px){

.bannerText__1FKTO{
    font-size: 0.75rem
}
  }

.marker__HypJ_ {
  width: 8px;
}

.placesContainer__NmFEu {
  height: 55vh;
}

.placesContainer__NmFEu p {
    margin-bottom: 20px;
    color: #707070;
  }

@media (max-width: 576px){

.placesContainer__NmFEu {
    height: 60vh
}
  }

.placesList__12mSS {
  width: 100%;
  height: 50vh;
  overflow-y: overlay;
}

@media (max-width: 576px){

.placesList__12mSS {
    height: 55vh
}
  }

.active__1i-C0 {
  background-color: rgb(249,138,207, 0.3);
  border: none;
}

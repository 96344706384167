.wrapper__1cPFA{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageWrapper__20RS3{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
}

.optionsWrapper__3t1y_{
    display: flex;
    flex-direction: column;
}

.title__1rrxK{
    max-width: 30rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin: 30px 10px;
    align-self: center;
}

.subTitle__JWxAc{
    max-width: 30rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin: 30px 0px;
    align-self: center;
    color: #E38ACA;
}

.downloadWrapper__33wvm{
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.downloadBox__2tBpy{
    padding: 5px;
    width: 24rem;

    border: solid 2px transparent;
    border-radius: 20px;
    background-image: linear-gradient( 0deg, indigo, purple,  pink, orange,red);
    background-origin: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.downloadBox__2tBpy .contentInfo__5vOpT{
        background-color: white;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

.downloadBox__2tBpy .iconWrapper__jun0r{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        position: relative;
    }

.downloadBox__2tBpy .iconWrapper__jun0r img {
            border-radius: 50%;
            width: 4em;
            height: 4em;
        }

.downloadBox__2tBpy .iconWrapper__jun0r svg {
            position: absolute;
            background-color: white;
            padding: 10px;
            border-radius: 50%;
            fill: #FEC400;
            width: 3em;
            height: 3em;
            
        }

.downloadBox__2tBpy .moduleTitle__210vj{
        color: #CC298E;
        font-weight: bold;
        font-size: 0.75rem;
        margin: 15px 0px 10px 0px;
    }

.downloadBox__2tBpy .contentTitle__3QYqf{
        margin-bottom: 20px;
        text-align: center;
        font-size: 1rem;
        padding: 0px 10px;
    }

.downloadBox__2tBpy .buttonDownload__mcZvT{
        margin: 20px 0px 10px 0px;
        background-color: white;
        border-radius: 40px;
        padding: 10px 20px;
    }

.downloadBox__2tBpy .buttonDownload__mcZvT button {
            color: #9A2481;
            font-weight: bold;
            background-color: transparent;
        }

.buttonWrapper__cgfOi {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
  }

.buttonWrapper__cgfOi button {
        margin: 0px 10px;
    }

.graffitiFooter__1qrHB {
    position: fixed;
    width: 30rem;
    bottom: -9vh;
    left: -3vw;
  }
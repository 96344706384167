.burgerButton__2aRRF {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: #125EED;
  padding: 18px 15px;
}

  .burgerButton__2aRRF > div {
    height: 4px;
    width: 30px;
    border-radius: 2px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {.burgerButton__2aRRF {
    width: 45px;
    height: 45px;
    padding: 13px 11px
}

    .burgerButton__2aRRF > div {
      width: 22px;
      height: 2px;
    }
  }

.places__IdqMQ {
  background-color: #8368d8;
}

.social__ZdYfJ {
  background-color: #D4E3FF;
}

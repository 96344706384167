.modalContainer__28SWu {
  padding: 0;
  overflow: hidden;
  background-color: transparent;
}

.image__1LSAF {
  width: 100%;
  height: 100%;
}

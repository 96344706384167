.container__22O3s {
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.1fr;
  align-items: center;

  background-color: #ffffff;
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.15);
  padding: 12px;
  border-radius: 12px;
  margin-inline: 12px;
  max-width: 420px;

  transition: 0.2s;
  cursor: pointer;
}

  @media (max-width: 576px) {.container__22O3s {
    max-width: 85%
}
  }

  .container__22O3s * {
    margin-inline: 4px;
  }

  .container__22O3s p,
  .container__22O3s b {
    font-size: 0.75rem;
    white-space: break-spaces;
  }

  .container__22O3s b {
    margin: 0;
  }

  .container__22O3s img {
    max-height: 60px;
  }

.closeButton__200Va {
  width: 24px;
}

.btnWrapper__3mQRR {

}

.children__2SGH5 {
  display: flex;
  flex-direction: row;
}

.children__2SGH5 svg, .children__2SGH5 img {
    margin-right: 10px;
  }

.actionBtn__1BOgV {
  display: flex;
  align-items: center;
  height: 25px;
  border-radius: 33px;
  background-color: white;
  outline: none;

  font-size: 0.75rem;
  color: #343434;
}

.actionBtn__1BOgV,
.whiteButton__1sYRz {
  white-space: nowrap;
  padding: 17px;
  box-shadow: 2px 2px 1px rgb(0, 0, 0, 0.15);
  margin-bottom: 5px;
}

.arrowContainer__2PPtr .actionBtn__1BOgV:after {
    display: inline-block;
    content: '';
    height: 5px;
    width: 5px;
    margin-left: 8px;
    border-bottom: 2px solid #343434;
    border-right: 2px solid #343434;
    transform: rotate(45deg);
    transition-duration: 0.8s;
    transition-property: transform;
  }

.whiteButton__1sYRz {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  border-radius: 33px;
  border: 1px solid #125EED;
  background-color: white;
  outline: none;

  font-size: 0.75rem;
  color: #343434;
}

.active__g_hs5 {
  background-color: #0146C7;
  color: white;
}

.active__g_hs5 path {
  fill: white;
}

.actionBtn__1BOgV.active__g_hs5:after {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.label__3hWMR {
  font-size: 0.75rem;
}

.bar__3TV2T {
  height: 15px;
  margin-left: 8px;
}

.count__2ph0W {
  background-color: #D4E3FF;
  font-size: 0.65rem;
  font-weight: 600;
  color: #343434;
  border-radius: 100%;
  width: 13px;
  margin: 4px;
}

.optionsContainer__2iZpN {
  color: #4D4D4D;
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  padding: 15px;
}

.optionsContainer__2iZpN svg, .optionsContainer__2iZpN img {
    height: 12px;
    margin-right: 5px;
  }

@media(max-width: 768px){

.optionsContainer__2iZpN {
    left: 0px;
    width: 100%;
    padding: 0;
    bottom: 25px;
    background: none;
    position: fixed
}
  }

.options__2zdgO {
  display: grid;
  grid-template-columns: 50% 50%;
}

.options__2zdgO .bottom__2ATr5 {
  padding-bottom: 5px;
}

.menu__3MNw_ {
  border-top: 1px solid #E6E6E6;
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.menu__3MNw_ > * {
    padding: 6px 20px;
  }

@media(max-width: 768px){

.menu__3MNw_ {
    position: relative;
    z-index: 4
}
  }

.clear__2LLNR {
  color: #999999;
  font-weight: bold;
}

.headerWrapper__do1Yr{
    background-color: #125EED;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 5px 10px;
    min-height: 70px;
}

    .headerWrapper__do1Yr svg, .headerWrapper__do1Yr div{
        flex: 0.2;

    }

    @media (max-width: 576px){

    .headerWrapper__do1Yr svg, .headerWrapper__do1Yr div{
            flex: 0.5

    }
        }

    .headerWrapper__do1Yr p {
        flex:3;
        font-size: 1.5rem;

    }

    @media (max-width: 576px){

    .headerWrapper__do1Yr p {
            font-size: 1rem

    }
        }

.wrapper__l_3W6 {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.shineAnimation__2qPZO {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
}

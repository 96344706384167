.starRatings__2JJJf {
  font-size: 0.88rem;
  display: flex;
  grid-template-columns: 15px 5px;
  align-items: center;
  justify-content: flex-start;
}

  .starRatings__2JJJf svg {
    width: 10px;
    margin-right: 5px;
  }

  .starRatings__2JJJf > span {
    font-size: 0.5rem;
  }

.bottom-sheet__1EHrS{
  height: 450px;
  position: absolute;
  bottom: 350px;
  width: 100%;
  background-color: #fff;
  transform: translateY(100%);
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  padding: 0 20px;
  z-index: 3;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

  .bottom-sheet__1EHrS span{
    display: inline-block;
  }

  .bottom-sheet__1EHrS ul {
    overflow: overlay;
    height: 400px;
  }

  .bottom-sheet__1EHrS .sheet-tip__z1S6f{
    width: 35px;
    height: 5px;
    background-color: #e5e5e5;
    border-radius: 10px;
    display: block;
    margin: 12px auto;
  }

.mainDrawer__2PguP {
  display: flex;
  justify-content: left;
  width: 100%;
  max-width: 50vw;
  height: 200%;
  top: -50%;
  border-radius: 0px 100% 100% 0px;
  direction: rtl;
  overflow-y: overlay;
}

  @media (max-width: 768px) {.mainDrawer__2PguP {
    border-radius: 0;
    max-width: unset
}
  }

.white__JOVEd {
  background-color: #ffffff;
}

.themeColor__aH1bF {
  background-color: #D4E3FF;
}

.lightThemeColor__2dJXA {
  background-color: #D4E3FF;
}

.close__2eq-O {
  position: relative;
}

.themeColor__aH1bF .close__2eq-O {
  filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.6));
  -webkit-filter: drop-shadow(2px 2px 1px rgb(0 0 0 / 0.6));
}

.contentTitle__2BMUK {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 75px;
}

.contentTitle__2BMUK h1 {
    font-size: 1.2rem;
    font-weight: 500;
  }

@media (max-width: 768px) {

.contentTitle__2BMUK h1 {
      font-size: 1rem
  }
    }

.content__3bXta {
  width: 100%;
  position: relative;
  direction: ltr;
  top: 28%;
  height: 60%;
}

.lightThemeColor__2dJXA .content__3bXta {
  top: 25%;
}

.themeColor__aH1bF .content__3bXta {
  top: 22%;
}

.container__2g2i0 {
  overflow-y: overlay;
}

.white__JOVEd .container__2g2i0 {
  width: 90%;
  margin: 0 auto;
}

.graffiti__3vDWN {
  position: absolute;
  left: 50vh;
  top: -50px;
  width: 30%;
  z-index: -1;
}

@media (max-width: 768px) {

.graffiti__3vDWN {
    left: 25vh;
    width: 40%
}
  }

.graffitiFooter__1aR6b {
  position: absolute;
  width: 100%;
  bottom: 15vh;
  z-index: -1;
}

.commentsBackground__3CUDJ {
  width: 90%;
  margin: 20px 20px auto;
  overflow: overlay;
  height: 60vh;
}

  @media (max-width: 768px) {.commentsBackground__3CUDJ {
    width: 95%;
    margin: 20px auto
}
  }

.commomWrapper__dmcJR {
  background-color: #fff;
  border-radius: 23px;
  direction: ltr;
  margin-bottom: 20px;
}

.stampsHeader__PCjte {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  padding-bottom: 20px;
}

.stampsHeader__PCjte > h4 {
    margin: 0;
  }

.stampsHeader__PCjte > button {
    background: inherit;
    color: #125EED;
    font-weight: bold;
  }

@media (max-width: 768px) {

.stampsHeader__PCjte {
    padding-bottom: 10px
}
  }

.filtersContent__2qRsA {
  padding: 3% 5%;
}

.filtersContent__2qRsA > h4 {
    margin: 0;
    padding-bottom: 20px;
  }

@media (max-width: 768px) {

.filtersContent__2qRsA > h4 {
      padding-bottom: 10px
  }
    }

.filtersTitles__2UGqg {
  font-size: 0.8rem;
  color: #acacac;
}

.filterList__30SVQ {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 1em;
  padding-top: 10px;
}

@media (max-width: 768px) {

.filterList__30SVQ {
    flex-wrap: nowrap;
    overflow-x: auto
}
  }

.filterOption__1Uhnq {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background: #f7f7f9;
  margin-right: 8px;
  border-radius: 32px;
  padding: 8px 18px;
  outline: none;
}

.filterOption__1Uhnq img {
    width: 10px;
    margin-right: 5px;
  }

@media (max-width: 768px) {

.filterOption__1Uhnq {
    white-space: nowrap;
    font-size: 0.8rem
}
  }

.selectedFilterOption__NYdZE {
  background-color: #D4E3FF;
}

.unauthCommentText__1Cx7q {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.unauthCommentText__1Cx7q > p {
    margin: 5% 5%;
  }

.avatar__3vVH_ {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin-right: 5%;
}

.signupButton__bILPG {
  color: #fff;
  background-color: #125EED;
  margin-bottom: 1em;
  font-weight: bolder;
  text-transform: uppercase;
}

.authCommentText__21Wd9 {
  display: flex;
  align-items: center;
  width: 85%;
  margin: auto;
  padding: 5% 0;
}

.authCommentText__21Wd9 > form {
    width: 100%;
  }

.authCommentText__21Wd9 .owner__nGwUQ {
    display: flex;
    justify-content: flex-end;
  }

.authCommentText__21Wd9 textarea {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #D4E3FF;
    outline: none;
    resize: none;
    margin-bottom: 5%;
  }

.activeCommentText__s6Rw9 {
  background-color: #D4E3FF;
  height: 7rem;
  border-radius: 15px 15px 0 0;
  padding: 5% 5% 0 5%;
}

.commentText__yhraA:-ms-input-placeholder {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32.734' height='32.731' viewBox='0 0 32.734 32.731'%3E %3Cg id='Grupo_1' data-name='Grupo 1' transform='translate(-0.363 0)'%3E %3Cpath id='Caminho_1' data-name='Caminho 1' d='M26.547,30.551h-24v-24H16.968L19.15,4.367H2.545A2.182,2.182,0,0,0,.363,6.549v24a2.182,2.182,0,0,0,2.182,2.182h24a2.182,2.182,0,0,0,2.182-2.182V14.186l-2.18,2.181Z' fill='rgba(0,0,0,0.4)'/%3E %3Cpath id='Caminho_2' data-name='Caminho 2' d='M32.581,4.192,28.9.516a1.757,1.757,0,0,0-2.488,0L11.459,15.561l-1.211,5.248a1.757,1.757,0,0,0,1.713,2.106,1.832,1.832,0,0,0,.4,0l5.291-1.167L32.581,6.68a1.756,1.756,0,0,0,0-2.488ZM16.521,19.728l-3.993.884.927-3.96L24.714,5.316l3.077,3.077ZM29.021,7.16,25.944,4.083l1.691-1.724,3.1,3.1Z' fill='rgba(0,0,0,0.4)'/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
}

@media (max-width: 768px) {

.commentText__yhraA:-ms-input-placeholder {
    font-size: 0.8em
}
  }

.commentText__yhraA::placeholder {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32.734' height='32.731' viewBox='0 0 32.734 32.731'%3E %3Cg id='Grupo_1' data-name='Grupo 1' transform='translate(-0.363 0)'%3E %3Cpath id='Caminho_1' data-name='Caminho 1' d='M26.547,30.551h-24v-24H16.968L19.15,4.367H2.545A2.182,2.182,0,0,0,.363,6.549v24a2.182,2.182,0,0,0,2.182,2.182h24a2.182,2.182,0,0,0,2.182-2.182V14.186l-2.18,2.181Z' fill='rgba(0,0,0,0.4)'/%3E %3Cpath id='Caminho_2' data-name='Caminho 2' d='M32.581,4.192,28.9.516a1.757,1.757,0,0,0-2.488,0L11.459,15.561l-1.211,5.248a1.757,1.757,0,0,0,1.713,2.106,1.832,1.832,0,0,0,.4,0l5.291-1.167L32.581,6.68a1.756,1.756,0,0,0,0-2.488ZM16.521,19.728l-3.993.884.927-3.96L24.714,5.316l3.077,3.077ZM29.021,7.16,25.944,4.083l1.691-1.724,3.1,3.1Z' fill='rgba(0,0,0,0.4)'/%3E %3C/g%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;
  color: #000;
}

@media (max-width: 768px) {

.commentText__yhraA::placeholder {
    font-size: 0.8em
}
  }

.activeCommentText__s6Rw9:-ms-input-placeholder {
  background-image: none;
  position: absolute;
  top: 15%;
  padding-left: 5%;
}

.activeCommentText__s6Rw9::placeholder {
  background-image: none;
  position: absolute;
  top: 15%;
  padding-left: 5%;
}

.btnWrapper__2zzbz {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 576px) {

.btnWrapper__2zzbz {
    flex-wrap: wrap
}
  }

.btn__39XT1 {
  width: 100%;
  color: #7b7b7b;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 20px;
  margin-right: 12px;
  outline: none;
}

.btn__39XT1:last-of-type {
    margin-right: 0;
  }

.unreviewButton__DeoLY {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-left: 0;
  padding-right: 0;
}

.unreviewButton__DeoLY > svg {
    margin-right: 5%;
  }

.commentWrapper__3oHvW {
  width: 85%;
  margin: auto;
  padding: 3% 0;
}

.commentWrapper__3oHvW > span {
    color: #000;
    font-weight: 500;
  }

@media (max-width: 576px) {
    .commentWrapper__3oHvW > span {
      font-size: 0.85rem;
    }
  }

.moreOptionsWrapper__2pIZK {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.moreOptionsWrapper__2pIZK > button {
    background-color: inherit;
    outline: none;
  }

.moreOptions__1iTAi {
  position: absolute;
  border: none;
  padding: 3%;
  top: 20px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.29);
  background-color: #fff;
}

.moreOptions__1iTAi > ul > li + li {
    margin-top: 10%;
  }

.moreOptions__1iTAi > ul > li > button {
    background-color: inherit;
    display: flex;
    align-items: center;
    outline: none;
  }

.moreOptions__1iTAi > ul > li > button > svg {
      margin-right: 10px;
    }

.commentHeaderWrapper__39YGO {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}

.commentInfoWrapper__oix8U {
  display: flex;
  flex-direction: column;
}

.reviewInfoWrapper__10Jjk {
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.reviewInfoWrapper__10Jjk > span {
    margin-right: 7px;
  }

@media (max-width: 768px) {

.reviewInfoWrapper__10Jjk {
    flex-wrap: wrap
}
  }

.starRatings__34LDv {
  height: 20px;
  font-size: 0.8rem;

  display: flex;
  align-items: center;
}

.starRatings__34LDv svg {
    width: 15px;
  }

.starRatings__34LDv > svg + svg {
    margin-left: 3px;
  }

.starRatings__34LDv span {
    margin: 1px 5px 0px 5px;
  }

.likes__NN76p {
  margin-top: 3%;
  padding: 2% 0;
  border-top: 1px solid #cfcfcf;
  display: grid;
  grid-template-columns: 20% auto 100px;
  justify-items: center;
}

.likes__NN76p > span {
    margin-left: 2%;
    font-weight: 300;
    color: #4d4d4d;
  }

@media (max-width: 576px) {

.likes__NN76p {
    grid-template-columns: 25% auto 100px
}
  }

.like__2pRHJ {
  display: flex;
  align-items: center;
}

.likeBtn___uFoa {
  margin-right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 36'%3E %3Cpath id='Caminho_1' data-name='Caminho 1' d='M19.5,36a1.2,1.2,0,0,1-.744-.262C11.979,30.35,7.311,25.71,4.044,21.133-.125,15.283-1.076,9.882,1.216,5.08,2.849,1.65,7.542-1.156,13.027.478A12.042,12.042,0,0,1,19.5,5.18a12.042,12.042,0,0,1,6.473-4.7c5.473-1.609,10.178,1.172,11.812,4.6,2.292,4.8,1.341,10.2-2.828,16.052C31.69,25.71,27.021,30.35,20.244,35.738A1.2,1.2,0,0,1,19.5,36ZM9.907,2.511a7.06,7.06,0,0,0-3.744.9A7.272,7.272,0,0,0,3.41,6.166c-1.889,3.966-1.036,8.382,2.609,13.483A70.418,70.418,0,0,0,19.5,33.169,70.418,70.418,0,0,0,32.981,19.661c3.657-5.114,4.5-9.529,2.609-13.483C34.371,3.684,30.714,1.7,26.643,2.873A9.686,9.686,0,0,0,23.09,4.84a9.953,9.953,0,0,0-2.469,3.271,1.241,1.241,0,0,1-.449.562,1.2,1.2,0,0,1-1.357,0,1.241,1.241,0,0,1-.449-.562A9.868,9.868,0,0,0,15.9,4.834a9.6,9.6,0,0,0-3.558-1.961,8.723,8.723,0,0,0-2.438-.362Z' fill='%234D4D4D'/%3E %3C/svg%3E");
  background-repeat: no-repeat;
  background-color: inherit;
  outline: none;
}

.likeActive__2o1rE {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 36'%3E %3Cpath id='Caminho_2' data-name='Caminho 2' d='M73.756,35.738a1.187,1.187,0,0,0,1.487,0C82.021,30.35,86.689,25.71,89.956,21.133c4.169-5.85,5.12-11.25,2.828-16.052-1.633-3.43-6.339-6.211-11.812-4.6A12.042,12.042,0,0,0,74.5,5.18a12.042,12.042,0,0,0-6.473-4.7C62.542-1.156,57.849,1.65,56.216,5.08c-2.292,4.8-1.341,10.2,2.828,16.052C62.311,25.71,66.979,30.35,73.756,35.738Z' transform='translate(-55)' fill='%23898989' fill-rule='evenodd'/%3E %3C/svg%3E");
}

.deleteCommentModal__17272 {
  min-width: 40vw;
}

.deleteCommentModalContent__3dj7K {
  width: 85%;
  height: 100%;
  margin: auto;
  text-align: center;
}

.deleteCommentModalContent__3dj7K > h1 {
    font-size: 1.5em;
    margin-bottom: 5%;
  }

.infoText__3CuhF {
  margin: 2% 0;
}

.areYouSureText__3ng0V {
  margin: 5% 0 15% 0;
}

.deleteCommentModalBtnWrapper__3FlUR {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.deleteCommentModalBtn__tBFqX {
  color: #7b7b7b;
  text-transform: uppercase;
  border-radius: 20px;
  margin-right: 12px;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.29);
  outline: none;
}

.deleteCommentModalBtn__tBFqX:last-of-type {
    margin-right: 0;
  }

@media (max-width: 768px) {

.deleteCommentModalBtn__tBFqX {
    font-size: 0.7em
}
  }

.btnConfirm__nLj6f {
  color: #fff;
  background-color: #0146C7;
  font-weight: bolder;
}

.deleteCommentFeedbackModal__3jE7j {
  min-width: 50vw;
}

.deleteCommentFeedbackForm__57pqX {
  width: 85%;
  margin: 5% auto 0 auto;
}

.deleteCommentFeedbackForm__57pqX label {
    color: #000;
  }

.deleteCommentFeedbackForm__57pqX label > p {
      text-align: left;
    }

.deleteCommentFeedbackForm__57pqX select {
    background-color: inherit;
    outline: none;
    padding: 2% 5%;
    border: 1px solid #dedede;
    border-radius: 30px;
  }

.deleteCommentFeedbackForm__57pqX textarea {
    margin-top: 7px;
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 15px;
    resize: none;
    outline: none;
    padding: 2%;
    height: 6rem;
    margin-bottom: 5%;
  }

p.errorText__1_QYa {
  position: relative !important;
}

.modal__1VXEr {
  width: 60%;
}

@media (max-width: 768px) {

.modal__1VXEr {
    width: 50%
}
  }

@media (max-width: 576px) {

.modal__1VXEr {
    width: 90%
}
  }

.modal__1VXEr h1 {
    margin-bottom: 30px;
  }

.modalContent__2_NNl {
  font-size: 0.75rem;
}

.modalContent__2_NNl > * {
  margin-bottom: 25px;
}

.heading__1bWPj {
  font-size: 0.85rem;
  margin-top: 16px;
}

.stamps__16A2M {
  margin-bottom: 32px;
}

.created__Msz26 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
}

.created__Msz26 svg {
    margin-right: 5px;
    width: 1.5rem;
  }

@media (max-width: 576px) {

.created__Msz26 {
    font-size: 0.8rem
}
    .created__Msz26 svg {
      width: 1rem;
    }
  }

.likeBtn___uFoa,
.likeActive__2o1rE {
  background-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 576px) {

.likeBtn___uFoa,
.likeActive__2o1rE {
    font-size: 0.8rem;
    background-size: 1rem;
    width: 1rem;
    height: 1rem
}
  }

.inline__1d0v5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.image__14CKZ {
  max-width: 50px;
  margin-right: 15px;
}

.description__1H1oH {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}

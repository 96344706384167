.buttonWrapper__2HknZ {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 36%);
  height: 30px;
}

  .buttonWrapper__2HknZ > a {
    margin: 0;
  }

  @media (max-width: 768px) {.buttonWrapper__2HknZ {
    margin: 0
}
  }

.btnBlock__3w2yj {
  margin: auto;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #0146C7;

  display: flex;
  align-items: center;
}

.btnBlock__3w2yj > svg {
    padding-right: 5px;
  }

.logged__27xYe {
  padding-left: 20px;
  padding-right: 25px;
}

.unlogged__2m3eS {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapper___7EFX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  height: 90vh;
}

  @media (max-width: 576px){.wrapper___7EFX {
    width: 95%
}
  }

.footer__3ibVo a {
  margin-bottom: 5px;
}

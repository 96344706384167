.noBottomMargin__2DO35 {
  margin-bottom: 10px;
}

.boxInput__HXmr- {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 95%;
  text-align: left;
}

@media (max-width: 768px) {

.boxInput__HXmr- {
    width: 100%;
    overflow-y: overlay;
    margin-top: 0
}
  }

.navButtons__q-JJ7 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
}

.navButtons__q-JJ7 > :first-child {
    margin: 5px 0;
    margin-bottom: 3vh;
  }

.navButtons__q-JJ7 > a,
  .navButtons__q-JJ7 > button {
    width: 100%;
  }

.signUp__2ITDF {
  display: flex;
  align-items: center;
  font-weight: bold;
  flex-direction: column !important;
  font-size: 1rem;
}

.signUp__2ITDF a {
    display: block;
    margin-top: 15px;
  }

.forgotPassword__n4TIj {
  color: #125EED;
  font-size: 0.75rem;
  display: block;
  text-align: left;
  font-weight: bold;
}

.wrapper__2AkCr {
  overflow: overlay;
  padding: 20px;
  max-height: 70vh;
}

  .wrapper__2AkCr h2, .wrapper__2AkCr h3 {
    text-align: center;
    margin-bottom: 15px;
  }

  .wrapper__2AkCr > p {
    margin-bottom: 15px;
    word-break: break-all;
  }

  .wrapper__2AkCr br {
    content: "A";
    display: block;
    margin-bottom: 10px;
  }

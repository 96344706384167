.stamp__2S8pa {
  text-align: center;
}

  .stamp__2S8pa > img {
    width: 60px;
    height: auto;
  }

  .stamp__2S8pa > h4 {
    margin: 0;
    font-weight: normal;
    font-size: 0.75rem;
    text-transform: capitalize;
  }


.emptyStamp__kK4ke {
  display: grid;
  width: 70px;
  grid-template-columns: repeat(2, 100px);
  align-items: center;

}


.emptyStamp__kK4ke > img {
    width: inherit;
    height: auto;
  }


@media (max-width: 768px) {


.emptyStamp__kK4ke > img {
      max-height: 70px;
      max-width: 70px
  }
    }


.emptyStamp__kK4ke > h3 {
    text-align: left;
    font-weight: 300;
    color: #D8DADE;
    font-size: 0.90rem;
  }


.modal__3jy8x {
  width: 60%;
}


@media (max-width: 768px) {


.modal__3jy8x {
    width: 50%
}
  }


@media (max-width: 576px) {


.modal__3jy8x {
    width: 90%
}
  }


.modal__3jy8x > * {
  margin-bottom: 25px;
}


.description__2N-36 {
  font-size: 0.75rem;
}


.description__2N-36 p {
    margin-bottom: 20px;
  }


.wrapper__1z5pm {
  display: grid;
  gap: 5%;
  grid-template-columns: 100px auto;
  text-align: left;
}


.wrapper__1z5pm h4 {
    font-weight: 600;
    color: #4C4C4C;
  }


.statistics__2f9w- {
  border-top: 1px solid #D8DADE;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.statistics__2f9w- img {
    width: 10px;
    margin-right: 2px;
  }


.statistics__2f9w- span {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 0.65rem;
    background: white;
    margin-right: 5px;
    border-radius: 32px;
  }

.btn__1qUlX {
  border-radius: 26px;
  border: solid 1px #ffffff;
  font-size: 0.875rem;
  padding: 10px 27px;
  text-align: center;
  cursor: pointer;
  position: relative;
  outline: none;
}

@media (max-width: 600px) {
  .btn__1qUlX {
    padding: 12px;
    font-size: 0.75rem;
  }
}

.primary__2zQRI {
  background-color: #ffffff;
  color: #0BBFCB;
  border: 0;
}

.transparent__Z1xiz {
  background-color: transparent;
  color: #ffffff;
}

.link__1NpDw {
  display: contents;
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
  border: 0;
  padding: 0;
  color: #0146C7;
  background-color: transparent;
}

.border__ntLXA {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
}

.danger__z5XZ8 {
  background-color: #cc504c;
  color: #ffffff;
  font-weight: 600;
}

.none__3UT9L {
  background-color: transparent;
  border: none;
  padding: 0;
}

.main__jcttS {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  box-shadow: 0 5px 14px -9px rgba(0, 0, 0, 0.8);
  outline: 0;
}

.themeColor__2Qpia {
  background-color: #0146C7;
  color: #ffffff;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.lightThemeColor__3Z_0F {
  background-color: #D4E3FF;
  color: #4C4C4C;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.themeColorLink__3RTGy {
  background-color: transparent;
  color: #125EED;
  display: contents;
  text-decoration: underline;
  border: 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.75rem;
}

.darkThemeColor__SDF4j {
  background-color: #0146C7;
  color: #ffffff !important;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.whiteThemeColor__1MXQ2 {
  background-color: #ffffff;
  color: #0146C7;
  border: 1px solid #0146C7 !important;
  font-weight: 600;
  border: none;
  box-shadow: none;
}

.grayLink__2uUdx {
  background-color: transparent;
  color: #909090;
  display: contents;
  text-decoration: underline;
  border: 0;
  padding: 0;
  font-weight: bold;
  font-size: 0.75rem;
}

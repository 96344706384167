.positionControl__9glp3 {
  width: 52px;
  height: 52px;
  border: 1px solid #d0d0d0;
  border-radius: 52px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .positionControl__9glp3 > img {
    width: 20px;
  }

  .positionControl__9glp3:active {
    background-color: #f8f8f8;
    border-color: transparent;
  }

.termsModal__1MiV4 {
  color: black;
  padding: 20px;
  text-align: center;
}

.wrapper__2BkeA {
  overflow: overlay;
  max-height: 70vh;
}

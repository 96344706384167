.inline__3PGfO {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.marginBlock5__SBIxb {
  margin-block: 5px;
}

.marginBlock5__SBIxb svg {
      width: 9.6px;
    }

.marginBlock24__21yHd {
  margin-block: 24px;
}

.verticalStack__1HNAk {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.marginLeft12__3PmHD {
  margin-left: 12px;
}

.flexGrowLow__j2qsU {
  flex: 0.1;
}

.userPhoto__3NSTX {
  border-radius: 100%;
  max-width: 50px;
}

.likes__25aWJ {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  align-items: center;
}

.likes__25aWJ svg {
    color: #c4c4c4;
  }

.likes__25aWJ.liked__2T7sM svg {
    color: #0146C7;
  }

.starsLabel__2-CLB {
  color: #707070;
  font-weight: bold;
  font-size: 14px;
  margin: 3px;
}

.commentText__2ylMh {
  color: #8d8d8d;
}

.sentDate__2P69l {
  font-weight: bold;
  font-size: 13px;
}

.replyButton__3mvmG {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 4px;
}

.replyButton__3mvmG svg {
    margin-left: 2px;
    margin-right: 2px;
  }

.btnWrapper__1vb-6 {
  display: flex;
  flex-direction: row;
}

.btnConfirm__2tJEt {
  color: #fff;
  background-color: #0146C7;
  font-weight: bolder;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
}

.sendIcon__11hEi {
  margin-right: 5px;
}

.replyCommentText__Dzfkr {
  background-color: #D4E3FF;
  height: 5rem;
  border-radius: 15px 15px 0 0;
  padding: 5% 5% 0 5%;
  border: none;
}

.verticalBar__2Imdg {
  width: 5px;
  background-color: #8d68dc;
  border-radius: 99px;
  margin-right: 8px;
}

.answerBlock__1Ygft {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
}

.businessAnswer__3RnoZ {
  display: flex;
  flex-direction: column;
}

.answerTitle__ML12t {
  color: #8d68dc;
  font-size: 13px;
  font-weight: 700;
}

.answerText__3zqlI {
  color: #8d8d8d;
  font-weight: 500;
  font-size: 13px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.answerDate__22Sc9 {
  color: rgba(0, 0, 0, 0.7);
  font-weight: 700;
  font-size: 12px;
}

.editButton__3DIi8 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 3px;
}

.editButton__3DIi8 svg {
    margin-right: 3px;
  }

.feedBackBar__3WF6H {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  max-width: 250px;
  background-color: #EAEAEA;
  margin-top: 10px;
  border-radius: 50px;
  font-size: 11px;
  font-weight: 500;
  padding-left: 10px;
  justify-content: space-between;
  white-space: nowrap;
  color: #707070;
}

.feedBacks__2bOqn {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.feedBackOption__CKEfL {
  display: flex;
  margin-right: 5px;
  font-size: 10px;
  font-weight: 700;
  color: #707070;
  align-items: center;
}

.feedBackOption__CKEfL svg {
    margin-right: 2px;
    margin-left: 2px;
  }

.commentWrapper__32lkd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #ffffff;
}

.username__eI2sG {
  font-size: 0.75rem;
}

.comment__1H77f {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 20px;
}

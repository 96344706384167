.mainContainer__2LTzH {
  position: relative;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}

  .mainContainer__2LTzH > * {
    pointer-events: auto;
  }

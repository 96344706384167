.mainContainer__2dkSQ {
  position: relative;
  width: 100%;
  height: 80vh;
}

.mainContainerIos__Pgrdz {
  position: relative;
  width: 100%;
  height: 89vh;
}

.cookieConsentWrapper__DU3_y {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0146C7;
  display: flex;
  z-index: 5;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 0.75em;
  color: white;
  padding: 2px;
}

@media (max-width: 576px) {

.cookieConsentWrapper__DU3_y {
    height: 100px
}
  }

.cookieConsentWrapper__DU3_y span {
    font-size: 10px;
  }

.cookieConsentBtn__1PdgA {
  background-color: #cc298e;
  border-radius: 20px;
  padding: 6px 12px;
  color: white;
  font-size: 0.875em;
}

.mainMenu__2iZI0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  left: 25px;
  top: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 4;
}

.friendlyRoutesContainer__35KHO {
  z-index: 1;
}

.filters__39p_j {
  display: flex;
  flex-direction: row;
  z-index: 1;
}

.filters__39p_j > * {
    margin-left: 5px;
  }

@media (max-width: 576px) {

.filters__39p_j {
    overflow-x: overlay;
    width: 95vw
}
  }

.push__1yJOc {
  position: absolute;
  top: 80px;
  right: 0;
  width: 50%;
  margin-right: 30px;
}

@media (max-width: 576px) {

.push__1yJOc {
    margin-left: 0px;
    position: relative;

    top: 0;
    width: 100%
}
  }

.container__1UJ76 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  overflow-y: overlay;
  text-align: center;
  margin-top: 20px;
}

  .container__1UJ76 > * {
    margin-bottom: 25px;
  }

  @media(max-width: 576px){.container__1UJ76 {
    width: 100%
}
  }

.imageWrapper__X-cZH {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
}

.imageWrapper__X-cZH p {
    color: #343434;
  }

.image__W8LzJ {
  width: 100px;
  margin-right: 10px;
}

.emojiWrapper__2iFK0 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.button__9GISi {
  width: 100%;
  height: 40px;
}

.textarea__3_9oO {
  width: 100%;
}

.textarea__3_9oO p {
    text-align: center;
  }

.modal__2TQ8a {
  text-align: center;
  width: 80%;
}

.modal__2TQ8a img {
    width: 200px;
  }

.modal__2TQ8a > * {
    margin-bottom: 10px;
  }

.container__2vlK8 {
  width: 100%;
}

.signLanguageLink__3TNYo {
  display: block;
  color: #00448c;
}

.center__2pgIG {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media(max-width: 768px) {

.center__2pgIG {
    justify-content: center
}
  }

.maxWidthMd__2pT1p {
  max-width: 65px;
}

@media(max-width: 768px) {

.maxWidthMd__2pT1p {
    max-width: 45px
}
  }

.brandingImage__1zCYE {
  width: 9rem;
  margin-right: 1rem;
}

.marginInline12__3RgbW {
  margin-inline: 12px;
}

.footer__kxCpM {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-starts;
  align-items: center;
  max-width: 100%;
  margin-bottom: 15px;
  width: 100%;
  margin-top: 12px;
}

@media(max-width: 768px) {

.footer__kxCpM {
    justify-content: center
}
  }

.footer__kxCpM img {
    width: 9rem;
    margin-right: 1rem;
  }

.footer__kxCpM svg {
    width: 2.5rem;
    fill: #00448c;
  }

.footer__kxCpM svg:hover {
    fill: #000000;
  }

.footer__kxCpM > button,
  .footer__kxCpM a + button,
  .footer__kxCpM a {
    margin-right: 20px;
  }

@media (max-width: 576px) {

.footer__kxCpM {
    flex-direction: row
}

    .footer__kxCpM svg {
      width: 2rem;
    }
  }

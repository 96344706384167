.spaceBetween__3euML {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.withoutAlign__3m6G4 {
  align-items: normal;
}

.moduleProgressContainer__1Qg45 {
  max-width: 120px;
}

.moduleCounter__1Ckcw {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex-direction: column;

  width: 55%;

  margin: 28px;

  background-color: #D4E3FF;
  font-weight: bold;
  text-align: center;
}

.moduleCounter__1Ckcw h4 {
    font-size: 1.3rem;
    margin: 0;
    margin-inline: 8px;
    color: #125EED;
  }

.titleWithButton__3oZKb {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.titleWithButton__3oZKb h1 {
    font-size: 1rem;
    color: #4e4d4d;
  }

.titleWithButton__3oZKb p {
    margin-top: 12px;
    font-size: 0.9rem;
  }

.marginTop20__3E-zk {
  margin-top: 20px;
}

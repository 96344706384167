.card__3JH4t {
  border-radius: 12px;
  overflow: hidden;
  min-height: 100px;
  background-color: white;
  min-width: 130px;
  width: 130px;
  height: 160px;
  margin: 4px;
}

  .card__3JH4t img {
    width: 100%;
    height: 100px;
  }

  .card__3JH4t div {
    padding: 12px;
  }

  .card__3JH4t h1,
  .card__3JH4t h2,
  .card__3JH4t h3 {
    font-size: 0.8rem;
  }

.burguerButton__3sg-d {
  z-index: 21;
  background-color: undefined;
  width: 50px;
  height: 50px;
  padding: 16px 14px;
  margin-left: 3px;
  position: fixed;
  top: 40px;
}

  .burguerButton__3sg-d > div {
    height: 2px;
    width: 22px;
    border-radius: 1px;
    background-color: #ffffff;
  }

.mapLoading__3PzRE {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1;
  background-color: #e5e3df;
}
  .mapLoading__3PzRE > div {
    margin: auto;
  }

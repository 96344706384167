.wrapper__3NA1O {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  overflow-y: overlay;
}

  .wrapper__3NA1O p {
    color: #4C4C4C;
    text-align: left;
  }

.container__Llllg {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 30px;
  width: 95%;
}

@media(max-width: 576px){

.container__Llllg {
    align-items: center
}
  }

.container__Llllg > * {
    margin-bottom: 30px;
  }

.profile__1Z9TV p {
    margin-top: 15px;
  }

.info___PVkl {
  width: 95%;
}

.imgInfo__2E1Hh {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.imgInfo__2E1Hh img {
    width: 50px;
  }

.infoTable__2qaJM {
  width: 100%;
  margin-top: 15px;
  text-align: center;
  font-size: 0.85rem;
}

.infoTable__2qaJM ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }

.infoTable__2qaJM li {
    width: 150px;
  }

.infoTable__2qaJM ul.tableHeader__23DLm {
    background-color: #E6E6E6;
    border-radius: 15px 15px 0 0;
    color: #4D4D4D;
    padding: 10px;
  }

.infoTable__2qaJM ul.tableContent__19Q-N {
    border: 1px solid #E6E6E6;
    border-radius: 0 0 15px 15px;
    color: #999999;
    padding: 15px;
  }

.progress__3qDEo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pbWrapper__2Zu6H {
  display: flex;
  flex-direction: column;
  height: 20vh;
}

.pbWrapper__2Zu6H li {
  margin-top: 10px;
}

.pbLabel__wSP9d {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 4px;
}

.pbLabel__wSP9d strong {
    font-weight: bold;
  }

.pbLabel__wSP9d span {
    color: #999999;
  }

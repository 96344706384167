.container__3XlwF {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #125EED;
  border-radius: 30px;
  max-width: 48%;
  height: 24vh;
  padding: 0 15px;
}

  .container__3XlwF > div > img {
      height: 105px;
      margin-bottom: 8px;
    }

  @media (max-width: 1366px) and (max-height: 768px) {

  .container__3XlwF > div > img {
        height: 86px
    }
      }

  @media (max-width: 768px) {

  .container__3XlwF > div > img {
        height: 64px
    }
      }

  .container__3XlwF > div > p {
      font-size: 0.8rem;
    }

  @media (max-width: 576px) {

  .container__3XlwF > div > p {
        font-size: 0.6rem
    }
      }

  @media (max-width: 768px) {.container__3XlwF {
    max-width: 48%;
    padding: 0 10px
}
  }

.readMore__3rQrn p {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #125EED;
  }

@media (max-width: 1366px) and (max-height: 768px) {

.readMore__3rQrn p {
      font-size: 0.8em
  }
    }

.readMore__3rQrn svg {
    margin-left: 5px;
  }

@media (max-width: 768px) {

.readMore__3rQrn svg {
      height: 16px
  }
    }

.advantageTitle__1pPKp {
  font-weight: 600;
}

@media (max-width: 576px) {

.advantageTitle__1pPKp {
    width: 90%;
    margin: auto
}
  }

@media (max-width: 1366px) and (max-height: 768px) {

.advantageTitle__1pPKp {
    font-size: 0.8em
}
  }

.advantageText__1y1-d {
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 8px;
}

@media (max-height: 768px) {

.advantageText__1y1-d {
    font-size: 0.65rem
}
  }

.arrowRight__3phJc {
  stroke: #0146C7;
}

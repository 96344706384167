.typeDescription__E3kc6 {
  text-align: center;
  border: 1px solid #e6e6e6;
  border-radius: 12px;

  padding: 12px;
}

  .typeDescription__E3kc6 img {
    max-width: 75px;
  }

.header__2Pbtm {
  text-align: center;
}

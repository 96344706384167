.positionRelative__2v25s {
  position: relative;
  border-radius: 14px;
  margin: 3px;
  padding: 15px 10px 10px;
}

.iconCircle__I67fi {
  display: inline-flex;
  border-radius: 100%;
  margin-bottom: 12px;
  padding: 5px;
}

.iconCircle__I67fi.pink__1d3A7 {
  background-color: #CC298E;
  color: white;
}

.iconCircle__I67fi.themeColor__G4O_- {
  background-color: #125EED;
  color: white;
}

.iconCircle__I67fi.gray__3Z49Z {
  background-color: #9C9B9B;
  color: white;
}

.iconCircle__I67fi.purple__hbpZX {
  background-color: #9A2481;
  color: white;
}

.iconCircle__I67fi.yellow__2hHr7 {
  background-color: #FEC400;
  color: white;
}

.iconCircle__I67fi.blue__14gYW {
  background-color: #00B8DA;
  color: white;
}

.header__m3Rji {
  color: #4E4D4D;
  font-size: 18px;
  font-weight: bold;
}

.subheading__ZCN6q {
  font-weight: 500;
  color: #7D7D7D;
  font-size: 13px;
}

.eyeIcon__1uCGs {
  margin-left: 8px;
  margin-bottom: 2px;
}

.footer__2CoUI {
  display: flex;
  flex: 1;
  align-items: flex-end;
}

.comingSoonPalette__3iq5c {
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  border-radius: 16px;

  top: 0;
  left: 0;
  -webkit-backdrop-filter: brightness(70%) grayscale(100%);
          backdrop-filter: brightness(70%) grayscale(100%);
}

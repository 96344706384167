.wrapper__1D3UX {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 70px;
}

.inputWrapper__16nEA {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.button__1OgBq {
  margin-right: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button__1OgBq span {
    font-size: 0.75rem;
    color: #343434;
  }

.input__thqfI {
  border: 1px solid #D8DADE;
  padding: 9px 20px;
  position: absolute;
  left: 0;
  width: 100%;
}

.themeColorLink__17CKo {
  position: absolute;
  right: 5px;
  margin-right: 10px;
  background: white;
}

.themeColorLink__17CKo svg {
    width: 14px;
  }

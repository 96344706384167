.container__WQx8d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90vh;
}

.content__3nqai {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.title__31iBM {
  color: #000000;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 3rem;
}

@media (max-width: 576px) {

.title__31iBM {
    font-size: 1.1rem
}
  }

@media (max-width: 280px) {

.title__31iBM { /*galaxy fold*/
    font-size: 0.8rem
}
  }

.description__1IVUs {
  color: #343434;
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-size: 0.8rem;
  height: 10vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

@media (max-width: 280px) {

.description__1IVUs { /*galaxy fold*/
    font-size: 0.6rem
}
  }

.hero__2T_bT {
  display: flex;
  align-items: center;
}

.hero__2T_bT > img {
    width: 200px;
    margin: 0 auto;
  }

.mainSearchbox__1zn0X {
  margin: auto;
  height: 55px;
  width: 70vh;
}

  @media (max-width: 576px) {.mainSearchbox__1zn0X {
    width: 80vw
}
  }

.innerWrapper__2amhP {
  padding: 1px 20px 1px 60px;
  box-shadow: 1px 1px 5px #D8DADE;
  -webkit-box-shadow: 1px 1px 5px #D8DADE;
  -moz-box-shadow: 1px 1px 5px #D8DADE;
}

.loader__LUUGs {
  right: 50px;
}

.mainSearchboxInput__5BsFi {
  font-size: 0.8rem;
}

@media (max-width: 768px) {

.mainSearchboxInput__5BsFi {
    font-size: 0.65rem
}
  }

.filterMain__3jULQ {
  position: absolute;
  top: 0;
  z-index: -1;
}

.mainSearchboxWrapper__3F1ej {
  position: relative;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

@media (max-width: 768px) {

.mainSearchboxWrapper__3F1ej {
    margin-top: 0
}
  }

.inputActive__13XSS {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes movedown__b0Jjb {
  0% {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    top: 0;
  }

  48% {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  49% {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  100% {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 100%;
  }
}

.wrapper__2tsT7 {
  position: relative;
  display: flex;
  width: 20px;
}

.shine__2ZTss {
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  animation: shine__2ZTss 600ms ease;
  animation-iteration-count: infinite;
}

.first__1Jnmx {
  transform: rotate(80deg);
}

.second__3TXB9 {
  transform: rotate(120deg);
  top: 2px;
  left: 12px;
}

.third__1GuKo {
  transform: rotate(160deg);
  top: 11px;
  left: 21px;
}

@keyframes shine__2ZTss {
  0% {
    width: 2px;
  }
  50% {
    width: 15px;
  }
  100% {
    width: 2px;
  }
}

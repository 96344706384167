.bodyOpen__2xFZ0 {
  overflow: hidden;
}

.portal__1DhhF {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  min-height: 100vh;
}

.clean__3Qtlu {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.modal__3SuGY {
  position: relative;
  animation: mount__1aMDJ 300ms ease-in;
  animation-fill-mode: forwards;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: 466px;
  max-width: 90vw;
  margin: 0;
  background-color: #ffffff;
  border-radius: 16px;
  max-height: 98vh;
  transition: transform 120ms linear;
  outline: none;
  padding: 45px 25px;
}

.modal__3SuGY.close__2DYLK {
    animation: unmount__39N7w 300ms ease-out;
    animation-fill-mode: forwards;
  }

.closeButtonModal__Q1Agf {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  z-index: 10;
}

@keyframes mount__1aMDJ {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes unmount__39N7w {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.wrapper__3pX_Z {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 99%;
  margin: 0 auto;
  height: 93vh;
  text-align: center;
  font-family: 'Montserrat', 'Lato', sans-serif;;
}

  @media (max-width: 576px){.wrapper__3pX_Z {
    width: 95%
}
  }

.body__UmzwK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 95%;
  align-items: center;
}

.body__UmzwK > * {
    margin-bottom: 30px;
  }

.hero__vQChM {
  height: 166px;
}

.rewards__IczE0 > * {
    margin-top: 10px;
  }

.rewards__IczE0 p {
    font-size: 0.85rem;
    line-height: 130%;
    color: #4C4C4C;
  }

.levelBar__3NiHn {
  display: flex;
  flex-direction: column;
}

.levelBar__3NiHn .level__2DSyg {
  margin: 0;
}

.couponsBtn__1a1nT {
  background: #0146C7;
  width: 300px;
}

.auth__3ZOV- {
  border-radius: 0px 0px 20px 20px;
  top: -8px;
  width: 100%;
}

.underline__2leha {
  color: #0146C7;
  text-decoration: underline;
  font-weight: bold;
}

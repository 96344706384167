.fullWidth__Xc57k {
  width: 100%;
}

.marginBlock12__qQ8b8 p {
  margin-top: 12px;
}

.pcd__2RPvI {
  text-align: left;
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
}

.pcd__2RPvI label {
    color: #000000;
  }

@media (max-width: 768px) {

.pcd__2RPvI label {
      font-size: 0.75rem
  }
    }

.pcd__2RPvI p {
    text-align: left;
    color: #343434;
    font-weight: 600;
    font-size: 0.75rem;
  }

.pcdradio__3EjaK {
  margin-top: 7px;
  border: 2px solid white;
  box-shadow: 0 0 0 1px #0146C7;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  margin-left: 10px;
  margin-right: 5px;
}

.pcdradio__3EjaK:checked {
  background-color: #0146C7;
}

.footerActions__2uDyR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  gap: 1.5rem;
}

.flex1__1F9dX {
  display: flex;
  flex: 1;

  justify-content: center;
}

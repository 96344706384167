.wrapper__TH_u0 {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    align-items: center;

  background-color: #ffffff;
}

  .wrapper__TH_u0 a {
    float: left;
    position: relative;
    display: inline-block;
    color: #D8DADE;
    text-align: center;
    text-decoration: none;
    font-size: 0.85rem;
  }

  .wrapper__TH_u0 a:hover {
    color: #000000;
  }

  .wrapper__TH_u0 a.active__1DoAt {
    color: #000000;
  }

  .wrapper__TH_u0 a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    position: absolute;
    left: 0;
    background: #000;
    transition: width 0.3s;
  }

  .wrapper__TH_u0 a:hover:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    position: absolute;
    left: 0;
    transition: width 0.3s;
  }

  .wrapper__TH_u0 a.active__1DoAt:after {
    width: 100%;
    color: #000000;
    background: #125EED;
    position: absolute;
    left: 0;
    transition: width 0.3s;
  }

.disabled__2OkcQ {
  cursor: not-allowed;
}

.disabled__2OkcQ > a {
    cursor: not-allowed;
  }

.disabled__2OkcQ > a:hover {
    color: #D8DADE;
  }

.disabled__2OkcQ > a.active__1DoAt {
    color: #D8DADE;
  }

.disabled__2OkcQ > a:after {
    content: "";
    margin-top: 5px;
    display: block;
    height: 2px;
    width: 0px;
    background: #D8DADE;
  }

.disabled__2OkcQ > a:hover:after,
  .disabled__2OkcQ > a.active__1DoAt:after {
    all: initial;
  }

.chips__YD2xE {
  min-height: 72px;
  max-height: 72px;
  border: 1px solid #D8DADE;
  line-height: 1;
  border-radius: 15px;
  font-size: 0.65rem;
  overflow-y: overlay;
}

.chipsInput__1M6gs {
  display: inline-block;
  min-height: 36px;
  margin-bottom: 5px;
  margin-left: 10px;
  width: 90%;
  border: 0;
  outline: none;
  font-size: 0.65rem;
}

.chip__121Pz {
  display: inline-block;
  margin: 5px;
}

.chip__121Pz .chipValue__2OEaH {
  display: inline-block;
  padding: 5px;
  padding-left: 15px;
  padding-right: 7.5px;
  background: #D4E3FF;
  color: #343434;
  border-radius: 15px;
}

.chip__121Pz .chipDeleteButton__3qfPa {
  background: #0146C7;
  color: #fff;
  border: 0;
  margin-left: 10px;
  border-radius: 25px;
  padding: 5px;
  padding-bottom: 5.3px;
  cursor: pointer;
  line-height: 0.5;
}

input[type="text"][disabled] {
   display: none;
}

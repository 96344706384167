.profileDataField__3pnAP {
  max-width: 100%;
  margin: auto;
  margin-bottom: 10px;
  display: block;
}

  .profileDataField__3pnAP > p {
    text-align: left;
    padding-left: 10px;
    color: black;
    font-weight: 600;
  }

  .profileDataField__3pnAP > input,
  .profileDataField__3pnAP > div,
  .profileDataField__3pnAP > select {
    max-width: 100%;
    height: 45px;
    border-radius: 20px;
    font-size: 1em;
  }

  @media (max-width: 576px) {

  .profileDataField__3pnAP > input,
  .profileDataField__3pnAP > div,
  .profileDataField__3pnAP > select {
      height: 32px;
      font-size: 0.9em
  }
    }

  .profileDataField__3pnAP > input,
  .profileDataField__3pnAP > select {
    border: 1px solid #d5d5d5;
  }

  .profileDataField__3pnAP > .error__2H_so {
    border: 1px solid rgba(255, 0, 0, 0.6);
  }

.selectField__1iEnL {
  color: black;
}

.selectField__1iEnL > label {
    color: black;
  }

.selectField__1iEnL > select {
    outline: none;
    background-color: #fff;
  }

.fullWidth__2-TTx {
  width: 100%;
}

.marginBlock12__2L7jV {
  margin-block: 12px;
}

.pcd__3zwMF {
  text-align: left;
  display: flex;
  margin-bottom: 10px;
}

.pcd__3zwMF label {
    color: #000000;
  }

@media (max-width: 768px) {

.pcd__3zwMF label {
      font-size: 0.85rem
  }
    }

.pcd__3zwMF p {
    text-align: left;
    padding-left: 10px;
    color: black;
    font-size: 0.85rem;
  }

.pcdradio__1bpU8 {
  margin-top: 7px;
  border: 2px solid white;
  box-shadow: 0 0 0 1px #0146C7;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  margin-left: 10px;
  margin-right: 5px;
}

.pcdradio__1bpU8:checked {
  background-color: #0146C7;
}

.footerActions__2GyeA {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 1.5rem;
}

.flex1__1WaVK {
  display: flex;
  flex: 1;

  justify-content: center;
}

body {
  background-color: #D4E3FF;
}

.topBanner__1_k7D {
  position: relative;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.04);

  background-color: white;

  min-height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  overflow-y: hidden;
}

.graffiti__vF7kR {
  position: absolute;
  left: 40vw;
  top: -50px;
  width: 40%;
}

@media (max-width: 768px) {

.graffiti__vF7kR {
    left: 25vh;
    width: 50%
}
  }

.tabContent__1XRNm {
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-inline: auto;
  margin-block: 24px;
  max-width: 40vw;
  max-height: calc(100vh - 270px);

}

@media(max-width: 768px) {

.tabContent__1XRNm {
    max-width: 95vw;
    max-height: calc(92vh - 270px)

}
  }

.level__KxPKt {
  padding: 10px;
  border: 2px solid #EFF0F2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 90px auto 80px;
  align-items: center;
  min-height: 110px;
  height: auto;
  background-color: #ffffff;
  cursor: pointer;
  min-width: 310px;
  width: 50vmin;
}

  @media(max-width: 576px){.level__KxPKt {
    grid-template-columns: 80px auto 60px;
    min-width: 280px
}
  }

.description__2KmHM {
  text-align: left;
  padding: 0px 15px 0px 15px;
}

.description__2KmHM p {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 3px;
  }

@media(max-width: 768px) {

.description__2KmHM {
    padding: 0px 5px
}
  }

.exchangeValue__1tGab {
  border-left: 2px solid #EFF0F2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exchangeValue__1tGab strong {
    font-size: 1.25rem;
  }

.exchangeValue__1tGab p {
    font-size: 0.75rem;
  }

.inactive__37_Yb {
  border: 2px solid #B3B6BF;
}

.inactive__37_Yb img {
    filter: grayscale(100%);
  }

.inactive__37_Yb .exchangeValue__1tGab {
    color: #B3B6BF;
  }

.link__3lPcU {
  display: flex;
  flex-direction: row;
  color: #0146C7;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 10px;
}

.link__3lPcU svg {
    width: 10px;
    margin-left: 5px;
    fill: #0146C7;
    transform: scaleX(-1);
    margin-top: 3px;
  }

.active__23NDt {
  color: #02D78A;
}

.active__23NDt svg {
    fill: #02D78A;
  }

.display__EZZ_j {
  display: none;
}

.levelLabel__2ZFPV {
  text-decoration: underline;
  font-size: 1rem;
}

.bronze__3x4Bh {
  color: #F7B478;
}

.silver__CRt_U {
  color: #e3e3e3;
}

.gold__2_qUr {
  color: #ffd500;
}

.analysisText__3UW51{
  margin-bottom: 20px;
  color: #8D68DC;
  font-weight: 600;
}
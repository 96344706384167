.stamps__HM_xV {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: overlay;
  margin-bottom: 15px;
  justify-items: center;
}

.stamps__HM_xV div {
  margin-inline: 8px;
}

.stamps__HM_xV div:first-child {
  margin-left: 0;
}

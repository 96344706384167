.roundButton__1pis9 {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.roundButton__1pis9:disabled {
  cursor: not-allowed;
}

.active__tNncf {
  width: 100px;
  height: 100px;
}

.boxInput__3BIRy {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 65%;
}

  .boxInput__3BIRy input {
    border: 1px solid #D8DADE;
    padding: 20px;
  }

  .boxInput__3BIRy ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #D8DADE;
  }

  .boxInput__3BIRy ::-moz-placeholder {
    /* Firefox 19+ */
    color: #D8DADE;
  }

  .boxInput__3BIRy :-ms-input-placeholder {
    /* IE 10+ */
    color: #D8DADE;
  }

  .boxInput__3BIRy :-moz-placeholder {
    /* Firefox 18- */
    color: #D8DADE;
  }

  .boxInput__3BIRy p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .boxInput__3BIRy p span {
      font-size: 0.8rem;
      font-weight: 500;
    }

  @media (max-width: 768px) {.boxInput__3BIRy {
    width: 100%;
    overflow-y: overlay;
    margin-top: 0
}
  }

.navButtons__1Tmef {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 12px;
}

.navButtons__1Tmef> :first-child {
    margin: 5px 0;
    margin-bottom: 3vh;
  }

.navButtons__1Tmef>a,
  .navButtons__1Tmef>button {
    width: 100%;
  }

.modal__3ukoV {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 35vh;
  width: 25vw;
}

.modal__3ukoV div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 70%;
  }

.modal__3ukoV div p {
      font-size: 0.8rem;
      color: #343434;
    }

.modal__3ukoV div a {
      width: 50%;
    }

.success__1_4Ks {
  font-family: 'Montserrat', 'Lato', sans-serif;;
  font-weight: 500;
  font-size: 1rem;
  line-height: 29px;
  color: #02D78A;
  margin-bottom: 1vh;
}

@media (max-width: 576px) {

.success__1_4Ks {
    font-size: 0.7rem
}
  }

.forgetPassword__3y9jQ {
  margin-bottom: 16px;
}

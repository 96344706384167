.stampDescription__32tk3 {
  font-size: 14px;
  color: #4c4c4c;
}

.trainingStamp__2Lqmh {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.stampBox__2UezR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.stamp__3fpIT {
  margin: auto;
}

.stampDescription__32tk3 {
  font-size: 14px;
  color: #4c4c4c;
}

.btn__2A9_Z {
  position: fixed;
  bottom: 40px;
  left: 25px;
  width: 350px;
  z-index: 9;
}

  @media (max-width: 576px) {.btn__2A9_Z {
    top: 100px;
    width: 90%;
    left: 20px
}
  }

.button__yDvMs {
  position: fixed;
  top: 40px;
  padding: 10px;
  height: 55px;
  background-color: #0146C7;
  border-radius: 40px 0 0 40px;
  right: -5px;
  z-index: 21;
}

.accordionPanel__1s42K {
  box-shadow: 0px 5px 1px rgb(0 0 0 / 25%);
}

.accordionButton__1FUCB {
  color: white;
  background-color: #0146C7;
  border-radius: 40px;
  font-size: 0.75rem;
  cursor: pointer;
  outline: none;
  padding: 10px;
  height: 55px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.accordionButton__1FUCB h2 {
    font-size: 0.8rem;
    font-weight: 500;
  }

@media (max-width: 576px) {

.accordionButton__1FUCB {
    top: 40px;
    width: 90vw;
    position: fixed
}
  }

.accordionButton__1FUCB:after {
  display: inline-block;
  content: "";
  height: 9px;
  width: 9px;
  margin: 0px 5px 5px 0px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(45deg);
  transition-duration: 0.8s;
  transition-property: transform;
}

@media (max-width: 576px) {

.accordionButton__1FUCB:after {
    display: none
}
  }

.accordionButton__1FUCB[aria-expanded="true"]::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin: 5px 0px 0px 5px;
}

.accordionButton__1FUCB[aria-selected="true"]::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin: 5px 0px 0px 5px;
}

@media (max-width: 576px) {

.accordionButton__1FUCB[aria-expanded="true"]::after,
.accordionButton__1FUCB[aria-selected="true"]::after {
    display: none
}
  }

.accordionPanel__1s42K {
  padding: 20px;
  margin-top: 10px;
  animation: fadein__hdYOZ 0.35s ease-in;
  color: #4d4d4d;
  border-radius: 8px;
  background-color: #ffffff;
  border-top: 1px solid #EFF0F2;
}

.accordionPanel__1s42K p {
    margin-bottom: 10px;
    font-size: small;
  }

.accordionPanel__1s42K a {
    color: #000000;
  }

.help__2cHDr {
  height: 20px;
  margin-right: 5px;
  text-align: center;
}

@media (max-width: 576px) {

.help__2cHDr {
    height: 25px
}
  }

.help__2cHDr path {
  stroke: white;
}

.pin__3x9qM {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #B3B6BF;
  padding: 10px 0px 10px 0px;
  font-size: 0.75rem;
  color: #343434;
}

.pin__3x9qM img {
    height: 50px;
    margin-right: 10px;
  }

.pin__3x9qM span {
    color: #999999;
    font-size: 0.75rem;
    font-weight: 400;
  }

.pin__3x9qM p {
    margin-top: 5px;
    font-size: 0.75rem;
  }

.pin__3x9qM:last-child {
    border: 0;
  }

.closeButton__3hJIH {
  border-color: #0146C7;
}

.footer__2kWqK {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 20px 0px 10px 0px;
  font-size: 0.75rem;
  color: #343434;
}

.footer__2kWqK button {
    display: block;
  }

.footer__2kWqK div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

.footer__2kWqK img {
    width: 35%;
  }

.footer__2kWqK p {
    margin-top: 5px;
    font-size: 0.75rem;
  }

/* -------------------------------------------------- */

/* ---------------- Animation part ------------------ */

/* -------------------------------------------------- */

@keyframes fadein__hdYOZ {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
